"use strict"
import config from "../config/config.js"
import axios from "../services/axios"
var axios2 = axios.getAxios()
var helpers = require("../helpers/helpers")

/**
 * API Handler replacing AXIOS with token based rule
 *
 */
function apiHandler() {
	this.fetch = async function (methodType, url, body, headers, callback) {
		let userData = JSON.parse(localStorage.getItem("userInfo"))
		if (userData && body) {
			body.__user = { email: userData.email ? userData.email : "", user_role: userData.user_role ? userData.user_role : "", mdw_id: userData.mdw_id ? userData.mdw_id : "", client_id: userData.client_id ? userData.client_id : "", discount: userData.discount ? userData.discount : "" }
		}
		let userAgent = false
		if (navigator) {
			userAgent = navigator.userAgent
		}
		if (window) {
			if (window.navigator) {
				userAgent = window.navigator.userAgent
			}
		}

		if (userAgent && body && body.__user) {
			body.__user["userAgent"] = userAgent
		}
		await axios2[methodType](config.apiUrl + url, body, headers)
			.then((response) => {
				callback(response)
			})
			.catch((err) => {
				if (err && err.response && err.response.status === 401) {
					localStorage.clear()
					sessionStorage.clear()
					window.location.replace("/login")
				} else if (err != undefined && err.response != undefined) {
					helpers.initAuthToken(err.response.status, err.response.statusText)
				}
				console.log(err)
			})
	}

	this.checkSession = async function () {
		try {
			const response = await axios2.get(config.apiUrl + "/checkSession", { withCredentials: true })
			console.log(response.data)
			if (response.data.status === "FAIL") {
				localStorage.clear()
				sessionStorage.clear()
				window.location.replace("/login") // Redirecting user to login page
			}
		} catch (error) {
			console.log(error)
		}
	}

	this.distorySession = async function () {
		try {
			const response = await axios2.get(config.apiUrl + "/distorySession", { withCredentials: true })
			console.log(response.data)
			if (response.data.status === "OK") {
				localStorage.clear()
				sessionStorage.clear()
				window.location.replace("/login") // Redirecting user to login page
			}
		} catch (error) {
			console.log(error)
		}
	}

	this.distoryAllSessions = async function () {
		try {
			const response = await axios2.get(config.apiUrl + "/distoryAllSessions", { withCredentials: true })
			console.log({
				sessionStorage: response.data,
			})
			if (response.data.status === "OK") {
				localStorage.clear()
				sessionStorage.clear()
				window.location.replace("/login") // Redirecting user to login page
			}
		} catch (error) {
			console.log(error)
		}
	}
}

apiHandler = new apiHandler()

export default apiHandler
