import update from 'react-addons-update'
import { CREATE_USER_GROUP, GET_USER_GROUP_FEATURES, ADD_FEATURES_TO_GROUP, ADD_FEATURES_TO_USER } from '../actions/groups_and_features/types.js'

const InitialUserGroupFeatures = [
	{
		data: {
			features_enabled: false,
			features_data: null,
		},
	},
]

export const getUserGroupFeatures = (state = InitialUserGroupFeatures, payload) => {
	switch (payload.type) {
		case GET_USER_GROUP_FEATURES:
			return update(state, {
				0: { $set: payload },
			})
			break
		default:
			return state
	}
}
