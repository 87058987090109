"use strict"
module.exports = {
	CONTROL_CLIENT_ID_1: "5f034f8d2465762075943c33",
	ROUTE_NLP_CONFIG: "/bots/botdashboard/nlpconfig",
	ROUTE_BOT_DASHBOARD: "/bots/botdashboard",
	ROUTE_SERVICE: "/bots/botdashboard/connections",
	ROUTE_clientConfig: "/bots/botdashboard/clientconfig",
	ROUTE_REPORTS: "/reports",
	ROUTE_USERS: "/users",
	ROUTE_ADMINUSERS: "/platformadminstration/platformusers",
	ROUTE_BOTS: "/bots",
	ROUTE_CUSTOMERS: "/platformadminstration/clients",

	STRING_SYS_ENTITY: "system-entity",
	ROUTE_ANALYZE: "/bots/botdashboard/analyze",
	STRING_MY_ENTITY: "user-entity",

	DEFAULT_APPOINTMENTAPP_START_TIME: [9, 0],

	RAZOR_KEY_ID:"rzp_test_p4XZ59JGjrjEsF",
	RAZOR_KEY_SECRET:"ENXrZKv7eHnRUF3r5uJTnqH9",

	REGEX_ALPH_NUM_HYPHEN: /^[_a-zA-Z0-9-]+$/,
	ERROR_MSG_ALPH_NUM_HYPHEN_VALIDATION: "Allows only alphanumeric(a-zA-Z0-9) and Hyphen(-) in key.",
	ENITITY_DUPLICATE_VALUE: "Entity Value Cannot be Duplicate in both global synonyms/entities",
	SYNONYM_DUPLICATE_VALUE: "Synonym Cannot be Duplicate in both global synonyms/entities",
	ENITITY_NAME_DUPLICATE_VALUE: "Entity Name Cannot be Duplicate in both global synonyms/entities",
	MESSAGE_FOR_DUPLICATE_EXAMPLES: "To add intent ,value should not be duplicate or empty",
	MESSAGE_FOR_DUPLICATE_INTENTS: "Intent Name Cannot be Duplicate",
	MESSAGE_FOR_DUPLICATE_GLOBAL_SYNONYM_VALUE: "To add Global Synonym  ,synonym value should not be duplicate or empty",
	MESSAGE_FOR_DUPLICATE_GLOBAL_SYNONYM_NAME: "Synonym Name Cannot be Duplicate in both global synonyms/entities",
	HTTPS_URL: "https://",
	KEYS: [
		"Accept",
		"Accept-Language",
		"Authorization",
		"Cache-Control",
		"Content-MD5",
		"Content-Type",
		"If-Match",
		"From",
		"If-None-Match",
		"If-None-Match",
		"If-Range",
		"Max-Forwards",
		"Pragma",
		"Proxy-Authorization",
		"Range",
		"Warning",
		"X-Requested-With",
		"X-Do-Not-Track",
		"DNT",
		"x-api-key",
		"Accept-Encoding",
		"Access-Control-Request-Headers",
		"Access-Control-Request-Method",
		"Connection",
		"Content-Length",
		"Cookie",
		"Cookie 2",
		"Content-Transfer-Encoding",
		"Date",
		"Expect",
		"Host",
		"Keep-Alive",
		"Origin",
		"Referer",
		"TE",
		"Trailer",
		"Transfer-Encoding",
		"Upgrade",
		"User-Agent",
		"Via",
	],
	VALUES: [
		"application/atom+xml",
		"application/ecmascript",
		"application/json",
		"application/javascript",
		"application/octet-stream",
		"application/ogg",
		"application/pdf",
		"application/postscript",
		"application/rdf+xml",
		"application/rss+xml",
		"application/soap+xml",
		"application/font-woff",
		"application/x-yaml",
		"application/xhtml+xml",
		"application/xml",
		"application/xml-dtd",
		"application/xop+xml",
		"application/zip",
		"application/gzip",
		"application/graphql",
		"application/x-www-form-urlencoded",
		"audio/basic",
		"audio/L24",
		"audio/mp4",
		"audio/mpeg",
		"audio/ogg",
		"audio/vorbis",
		"audio/vnd.rn-realaudio",
		"audio/vnd.wave",
		"audio/webm",
		"image/gif",
		"image/jpeg",
		"image/pjpeg",
		"image/png",
		"image/svg+xml",
		"image/tiff",
		"message/http",
		"message/imdn+xml",
		"message/partial",
		"message/rfc822",
		"multipart/mixed",
		"multipart/alternative",
		"multipart/related",
		"multipart/form-data",
		"multipart/signed",
		"multipart/encrypted",
		"text/cmd",
		"text/css",
		"text/csv",
		"text/html",
		"text/plain",
		"text/vcard",
		"text/xml",
	],
}
