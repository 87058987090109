const hostname = window && window.location && window.location.hostname

var subdomain_status = true
var domain = hostname //"doc-dev.imedworks.com"
var url_type = "https"
var app_port = false
var api_port = false

if (app_port) {
	app_port = ":" + app_port
} else {
	app_port = ""
}
if (api_port) {
	api_port = ":" + api_port
} else {
	api_port = ""
}

const config = {
	/*Local*/
	apiUrl: "/smartmrapi/api",
	oAuthCallback: "/smartmrapi/api/oauthcallback",
	oAuthSFCallback: "/smartmrapi/api/oauthsfcallback",
	//Chat intial context to be set
	contextData: {
		context_variables: {
			isAuthentication: false,
			conversationStart: true,
			loadDynamicParams: false,
		},
		entities: "",
		userMsg: "",
		coming_from: "webhook",
		sysMsg: [],
		request_name: "",
		previous_node: "",
		previous_node_backup: "",
		context_name: "",
		response_type: "",
		head: null,
		nodeEvaluationCount: {},
	},

	auth_types: [
		{
			type: "Service Account",
		},
		{
			type: "Basic Auth",
		},
		{
			type: "oAuth 1.0",
		},
		{
			type: "oAuth 2.0",
		},
	],

	responseProcessorList: [
		{
			type: "default",
			scriptCode: "",
		},
		{
			type: "Facebook",
			scriptCode: "",
		},
		{
			type: "Hangout",
			scriptCode: "",
		},
		{
			type: "Sms",
			scriptCode: "",
		},
		{
			type: "Webhook",
			scriptCode: "",
		},
		{
			type: "Email",
			scriptCode: "",
		},
		{
			type: "Slack",
			value: "",
		},
	],
}
//console.log(hostname); qcp-dev.scd.quirk.ai
config.apiUrl = url_type + "://" + domain + "/smartmrapi/api"
config.graphqlUrl = url_type + "://" + domain + "/graphql"
config.endUrl = domain
config.redirect_url = domain
config.domain_url = url_type + "://" + domain
config.chat_domain_url = "https://quirk-chat-dev.herokuapp.com"
config.socket_url = url_type + "://" + domain
config.serviceEnableStatus = false

if (!subdomain_status) {
	config.apiUrl = url_type + "://" + domain + "/smartmrapi/api"
	config.endUrl = domain
	config.redirect_url = domain
	config.domain_url = url_type + "://" + domain
	config.chat_domain_url = "https://quirk-chat-dev.herokuapp.com"
	config.socket_url = url_type + "://" + domain
	config.serviceEnableStatus = false
} else {
	config.apiUrl = url_type + "://" + domain + "/smartmrapi/api"
	//config.endUrl = "login." + domain
	config.endUrl = domain
	config.redirect_url = domain
	config.domain_url = url_type + "://" + domain
	config.chat_domain_url = "https://quirk-chat-dev.herokuapp.com"
	config.socket_url = url_type + "://" + domain
	config.serviceEnableStatus = false
}

export default config