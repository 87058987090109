// Import FilePond styles
import "filepond/dist/filepond.min.css"
import React, { Component } from "react"
import EventEmitter from "react-native-eventemitter"
import config from "../../../config/config.js"
import { IM_FileUpload } from "../Sections/Sections"
// import FilePondPluginImagePreview from "filepond-plugin-image-preview"
// import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css"

// Register the plugins
// registerPlugin(FilePondPluginImageExifOrientation)

// Component

export default class UploadFileS3 extends Component {
	constructor(props) {
		super(props)

		this.state = {
			// Set initial files, type 'local' means this is a file
			// that has already been uploaded to the server (see docs)
			files: [
				{
					source: "photo.jpeg",
					options: {
						type: "local",
					},
				},
			],
			fileItems: false,
			show: false,
			showButton: false,
		}
		this.makeRequest = this.makeRequest.bind(this)
		this.callbackJob = this.callbackJob.bind(this)
		this.handleInit = this.handleInit.bind(this)
	}
	componentDidMount() {
		if (this.state.fileItems !== false) {
			this.setState({ showButton: true })
		} else {
			this.setState({ showButton: false })
		}
		EventEmitter.on("showButton", (value) => {
			if (value) {
				this.setState({ show: false, showButton: false, hideButton: true })
			} else {
				this.setState({ show: true, showButton: true, hideButton: false })
			}
		})
	}

	// componentDidUpdate(prevProps, prevState){
	// 	if(prevState.fileItems.length !== this.state.fileItems){
	// 		if (this.state.fileItems.length > 0) {
	// 			this.setState({ showButton: true })
	// 		} else {
	// 			this.setState({ showButton: false })
	// 		}
	// 	}
	// }

	handleInit() {
		EventEmitter.on("capture", (value) => {
			// var block = value.split(";");
			// var contentType = block[0].split(":")[1];
			// // const data = { hello: 'world' };
			// // const blob = new Blob([JSON.stringify(data, null, 2)], {
			// // 	type: contentType
			// // });
			// var finalfile = new File([value], 'Capture', {'type': contentType});
			// console.log(finalfile)
			// var url = URL.createObjectURL(finalfile)
			// console.log(JSON.stringify(value))
			this.pond
				.addFile(value)
				.then((file) => {
					console.log(file)
				})
				.catch((error) => {
					console.log(error)
				})
		})
	}

	async makeRequest(fileItems) {
		if (fileItems !== false) {
			this.setState({ showButton: true, fileItems })
		} else {
			this.setState({ showButton: false, fileItems: false })
		}
		this.setState({
			files: await fileItems.map((fileItem) => fileItem.file),
		})
		// this.props.callback(this.state.files)
	}
	callbackJob(val) {
		if (val) {
			this.props.callbackShow(true)
			this.setState({ show: true, showButton: false, hideButton: true })
		} else {
			this.props.callbackShow(false)
			this.setState({ show: false, showButton: true, hideButton: false })
		}
	}

	render() {
		return (
			<div style={{ width: "100%", position: "relative" }}>
				<IM_FileUpload
					pond={(ref) => (this.pond = ref)}
					server={{
						process: (fieldName, file, metadata, load, error, progress, abort, transfer, options) => {
							const formData = new FormData()

							formData.append("client_id", this.props.client_id)
							formData.append("check_in_id", this.props.checkin_id)
							formData.append("type", this.props.type || "visit_summary")
							formData.append(fieldName, file, file.name)
							const request = new XMLHttpRequest()
							request.open("POST", config.apiUrl + "/utilities/files/add")
							request.upload.onprogress = (e) => {
								progress(e.lengthComputable, e.loaded, e.total)
							}
							request.onload = function () {
								if (request.status >= 200 && request.status < 300) {
									// the load method accepts either a string (id) or an object
									load(request.responseText)
									console.log(request.responseText)
									this.props.callbackFiles(request.responseText)
									let fileId = this.props.randString()
								} else {
									// Can call the error method if something is wrong, should exit after
									error("oh no")
								}
							}.bind(this)
							request.send(formData)
							return {
								abort: () => {
									// This function is entered if the user has tapped the cancel button
									request.abort()

									// Let FilePond know the request has been cancelled
									abort()
								},
							}
						},
						// revert: (uniqueFileId, load, error) => {
						// 	apiHandler.fetch("post", "/utilities/files/delete", { filedata: JSON.parse(uniqueFileId) }, {}, function (response) {
						// 		// load()
						// 	})
						// },
					}}
					allowMultiple={this.props.allowMultiple}
					// allowReplace={true}
					// allowRemove={true}
					name="attachments"
					oninit={() => this.handleInit()}
					onupdatefiles={async (fileItems) => {
						await this.makeRequest(fileItems)
					}}
				/>
			</div>
		)
	}
}
