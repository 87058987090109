import { combineReducers } from 'redux'
import { LOG_OUT } from '../actions/users/types'
import * as user_info from './user_info'
import * as user_routes from './user_routes'
import * as groups_and_features from './groups_and_features'

const appReducer = combineReducers({
	userInfo: user_info.getUserInfo,
	expiryPolicyData: user_info.getExpirePolicyData,
	userRoutes: user_routes.UpdateRoute,
	getUserGroupFeatures: groups_and_features.getUserGroupFeatures,
})

const rootReducer = (state, action) => {
	if (action.type === LOG_OUT) {
		state = []
	}

	return appReducer(state, action)
}

export default rootReducer
