import update from 'react-addons-update'
import { UPDATE_ROUTES } from '../actions/user_routes/types'

export const UpdateRoute = (state = [], payload) => {
	switch (payload.type) {
		case UPDATE_ROUTES:
			return update(state, {
				0: { $set: payload },
			})
			break
		default:
			return state
	}
}
