import React from "react"
import MUIDataTable from "mui-datatables"
import { createMuiTheme, MuiThemeProvider, withStyles } from "@material-ui/core/styles"

function CardsList(props) {
	const getMuiTheme = () =>
		createMuiTheme({
			overrides: {
				MUIDataTable: {
					root: {
						backgroundColor: "#EEE",
					},
					paper: {
						boxShadow: "none",
					},
				},
				MUIDataTableSearch: {
					main: {},
					searchIcon: {
						display: "none",
					},
					clearIcon: {
						display: "none",
					},
				},
				MuiFormControl: {
					fullWidth: {
						width: "80%",
					},
				},
				// MuiButtonBase:{
				// 	root:{
				// 		display:"none"
				// 	}
				// },
				MuiInputBase: {
					input: {
						fontSize: "0.8em",
					},
				},
				MuiTableRow: {
					root: {
						margin: "10px",
						display: "flex",
						flex: 1,
					},
					hover: {
						backgroungColor: "red",
					},
				},
				MuiTableCell: {
					root: {
						width: "100%",
						boxShadow: "0px 0px 50px 2px #eee",
						margin: "5px",
					},
				},
				MUIDataTableBodyCell: {
					root: {
						backgroundColor: "#FFF",
					},
				},
				MUIDataTableFilter: {
					title: {
						display: "none",
					},
				},
			},
		})

	let options = {
		caseSensitive: false,
		selectableRows: "none",
		rowsPerPage: 10,
		rowsPerPageOptions: [5, 10, 15, 20, 50, 100],
		search: "disabled",
		searchOpen: true,
		searchPlaceholder: "Search Doctor",
		filter: false,
		print: false,
		download: false,
		viewColumns: false,
		elevation: 0,
		jumpToPage: true,
		// isRowExpandable: () => true,
		// customFilterDialogFooter: () => <div>WOW Doctors</div>,
		customSearch: (searchQuery, currentRow, columns) => {
			searchQuery = searchQuery.toLowerCase()
			let isFound = false
			currentRow.forEach((col) => {
				if (col) {
					if (col.props && col.props.children ? col.props.children.toString().toLowerCase().indexOf(searchQuery) >= 0 : col.toString().toLowerCase().indexOf(searchQuery) >= 0) {
						isFound = true
					}
				}
			})
			return isFound
		},
	}
	return (
		<div style={props.style}>
			<MuiThemeProvider theme={getMuiTheme()}>
				<MUIDataTable
					data={props.doctors}
					columns={[
						{
							name: "Column",
							options: {
								filter: false,
								sort: true,
								customHeadRender: () => <></>,
							},
						},
						{
							name: "FirstName",
							options: {
								filter: false,
								sort: false,
								display: false,
							},
						},
						{
							name: "LastName",
							options: {
								filter: false,
								sort: false,
								display: false,
							},
						},
					]}
					options={options}
				/>
			</MuiThemeProvider>
		</div>
	)
}

export default CardsList
