const crypto = require("crypto"),
	algorithm = "aes-256-ctr",
	password = "d6F3Efeq"

function encrypt(text) {
	var cipher = crypto.createCipher(algorithm, password)
	var crypted = cipher.update(text, "utf8", "hex")
	crypted += cipher.final("hex")
	return crypted
}

function decrypt(text) {
	var decipher = crypto.createDecipher(algorithm, password)
	var dec = decipher.update(text, "hex", "utf8")
	dec += decipher.final("utf8")
	return dec
}

export const loadState = () => {
	try {
		let serializedState = localStorage.getItem("state")
		if (serializedState ===null) {
			return undefined
		}
		serializedState = decrypt(serializedState)
		return JSON.parse(serializedState)
	} catch (err) {
		return undefined
	}
}

export const saveState = (state) => {
	try {
		let serializedState = JSON.stringify(state)
		serializedState = encrypt(serializedState)
		localStorage.setItem("state", serializedState)
	} catch (err) {}
}

export const removeState = () => {
	localStorage.clear()
}
