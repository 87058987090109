'use strict'

import axios from 'axios'

/**
 * Axios Service
 *
 */
function axiosService() {
	this.getAxios = function () {
		var token = localStorage.getItem('authToken')
		var deviceId = localStorage.getItem('device-id')
		if (token) {
			axios.defaults.headers.common['Authorization'] = 'JWT ' + token
			axios.defaults.headers.common['device-id'] = deviceId
			return axios
		} else {
			return axios
		}
	}
}

axiosService = new axiosService()

export default axiosService
