"use strict"

import $ from "jquery"
import Razorpay from "razorpay"
import history from "../index"
import constants from "../config/constants"
import { callbackify } from "util"
import OnlineDoctors from "../views/Patients/external/OnlineDoctors"
import axios from "axios"
import fileDownload from "js-file-download"
var _ = require("lodash"),
	moment = require("moment"),
	crypto = require("crypto"),
	algorithm = "aes-256-ctr",
	password = "d6F3Efeq"

var head = document.head,
	link = document.createElement("link")
link.type = "text/css"
link.rel = "stylesheet"
link.href = "/css/ie.css"
head.appendChild(link)
var randomColor = () => Math.floor(Math.random() * 16777215).toString(16)

/**
 * Helpers
 *
 */
function helpers() {
	this.initAuthToken = function (statusCode, message) {
		if (statusCode === 401 && message === "Unauthorized") {
			localStorage.clear()
			sessionStorage.clear()
			history.push("/login")
		}
	}

	this.ucFirst = function (value) {
		return value.charAt(0).toUpperCase() + value.slice(1)
	}
	this.toTitleCase = function (str, includeAllCaps, includeMinorWords) {
		includeAllCaps = includeAllCaps ? (includeAllCaps == true ? true : false) : false
		includeMinorWords = includeMinorWords ? (includeMinorWords == true ? true : false) : false
		var i, j, lowers
		str = str.replace(/([^\W_]+[^\s-]*) */g, function (txt) {
			if (!/[a-z]/.test(txt) && /[A-Z]/.test(txt) && !includeAllCaps) {
				return txt
			} else {
				return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
			}
		})

		if (includeMinorWords) {
			return str
		} else {
			// Certain minor words should be left lowercase unless
			// they are the first or last words in the string
			lowers = ["A", "An", "The", "And", "But", "Or", "For", "Nor", "As", "At", "By", "For", "From", "In", "Into", "Near", "Of", "On", "Onto", "To", "With"]
			for (i = 0, j = lowers.length; i < j; i++)
				str = str.replace(new RegExp("\\s" + lowers[i] + "\\s", "g"), function (txt) {
					return txt.toLowerCase()
				})

			return str
		}
	}

	this.encrypt = function (data) {
		var text = JSON.stringify(data)
		var cipher = crypto.createCipher(algorithm, password)
		var crypted = cipher.update(text, "utf8", "hex")
		crypted += cipher.final("hex")
		return crypted
	}

	this.decrypt = function (text) {
		var decipher = crypto.createDecipher(algorithm, password)
		var dec = decipher.update(text, "hex", "utf8")
		dec += decipher.final("utf8")
		dec = JSON.parse(dec)
		return dec
	}

	this.getDatesDiffDays = function (to_date, from_date) {
		var timeDiff = Math.abs(to_date.getTime() - from_date.getTime())
		var diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24))
		return diffDays
	}

	this.getDateFromISO = function (dateISO) {
		let date = new Date(dateISO)
		let year = date.getFullYear()
		let month = date.getMonth() + 1
		let dt = date.getDate()

		if (dt < 10) {
			dt = "0" + dt
		}
		if (month < 10) {
			month = "0" + month
		}

		return dt + "-" + month + "-" + year
	}

	this.getDatesDiffInHours = function (date1, date2) {
		var ms = moment(date1).diff(moment(date2))
		var hours = moment.duration(ms)
		hours = Math.floor(hours.asHours())

		return hours
	}

	this.addCss = function (fileName, removeFile) {
		if ($("link[href='/css/" + fileName + ".css']").length) {
			return
		}
		var head = document.head,
			link = document.createElement("link")
		link.type = "text/css"
		link.rel = "stylesheet"
		link.href = "/css/" + fileName + ".css"
		head.appendChild(link)
		var filetype = "css"
		var targetelement = filetype === "js" ? "script" : filetype === "css" ? "link" : "none" //determine element type to create nodelist from
		var targetattr = filetype === "js" ? "src" : filetype === "css" ? "href" : "none" //determine corresponding attribute to test for
		var allsuspects = document.getElementsByTagName(targetelement)
		for (var i = allsuspects.length; i >= 0; i--) {
			//search backwards within nodelist for matching elements to remove
			if (allsuspects[i] && allsuspects[i].getAttribute(targetattr) !== null && allsuspects[i].getAttribute(targetattr).indexOf(removeFile) !== -1) {
				allsuspects[i].parentNode.removeChild(allsuspects[i]) //remove element by calling parentNode.removeChild()
			}
		}
	}

	this.addWebhookCss = function (fileName) {
		var filetype = "css"
		var targetelement = filetype === "js" ? "script" : filetype === "css" ? "link" : "none" //determine element type to create nodelist from
		var targetattr = filetype === "js" ? "src" : filetype === "css" ? "href" : "none" //determine corresponding attribute to test for
		var allsuspects = document.getElementsByTagName(targetelement)
		var cssFiles = ["smartmr_dark", "smartmr_light", "webhook"]
		_.forEach(cssFiles, function (removeFile) {
			for (var i = allsuspects.length; i >= 0; i--) {
				//search backwards within nodelist for matching elements to remove
				if (allsuspects[i] && allsuspects[i].getAttribute(targetattr) !== null && allsuspects[i].getAttribute(targetattr).indexOf(removeFile) !== -1) {
					allsuspects[i].parentNode.removeChild(allsuspects[i]) //remove element by calling parentNode.removeChild()
				}
			}
		})
		var head = document.head,
			link = document.createElement("link")
		link.type = "text/css"
		link.rel = "stylesheet"
		link.href = "/css/" + fileName + ".css"
		head.appendChild(link)
	}

	this.flattenCondtions = function (condtionData) {
		var finalizedArray = []
		var intermediateArray = []
		var str = condtionData
		var intializedArray = str.split(/([&&,|])/)
		var status = false
		_.forEach(intializedArray, function (item, index) {
			intermediateArray.push(item.trim())
		})

		_.forEach(intermediateArray, function (conditionValue, index) {
			if (conditionValue === "") {
				intermediateArray.filter(Boolean)
			} else {
				if (conditionValue === "&" && status === false) {
					finalizedArray.push(conditionValue.trim())
					status = true
				} else if (conditionValue === "&" && status === true) {
					finalizedArray.pop()
					finalizedArray.push("&&")
					status = false
				} else {
					finalizedArray.push(conditionValue.trim())
				}
			}
		})
		return finalizedArray
	}

	this.convertDate = function (dateValue, status) {
		let getTimeZone = moment(new Date()).format("Z")
		var timeStatus = getTimeZone.charAt(0)
		if (timeStatus === "+") {
			var timeValues = getTimeZone.substring(1, 10)
			timeValues = timeValues.split(":")
			var toDate = new Date(dateValue)
			if (status === "toDate") {
				toDate.setHours(toDate.getHours() + parseInt(timeValues[0]))
				toDate.setHours(toDate.getHours() + 23)
				toDate.setMinutes(59)
				toDate.setSeconds(59)
			} else {
				toDate.setHours(toDate.getHours() + parseInt(timeValues[0]))
			}
			toDate.setMinutes(toDate.getMinutes() + parseInt(timeValues[1]))
			return toDate
		} else {
			var timeValues = getTimeZone.substring(1, 10)
			timeValues = timeValues.split(":")
			var toDate = new Date(dateValue)
			if (status === "toDate") {
				toDate.setHours(toDate.getHours() - parseInt(timeValues[0]))
				toDate.setHours(toDate.getHours() + 23)
				toDate.setMinutes(59)
				toDate.setSeconds(59)
			} else {
				toDate.setHours(toDate.getHours() - timeValues[0])
			}
			toDate.setMinutes(toDate.getMinutes() - timeValues[1])
			return toDate
		}
	}

	this.isValidURL = function (URL) {
		try {
			if (URL !== "" && URL !== null && URL != undefined) {
				var regexp = /(ftp|http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/
				return regexp.test(URL)
			} else {
				return false
			}
		} catch (e) {
			return false
		}
	}

	this.convertMetricsData = function (vals, callback) {
		if (vals.type === "revenue") {
			let mainData = vals.main
			var lead = vals.lead
			var labels = vals.labels
			var datasets1 = []
			var datasets2 = []
			var newData = []

			var done2 = _.forEach(mainData, (item, i) => {
				let { data, name, status, type, month } = item
				var done = _.forEach(data, (itm, i) => {
					if (lead === "doctor") {
						if (_.find(datasets1, { label: itm[lead], month: itm.month }) === undefined) {
							datasets1.push({ label: itm[lead], data: itm.grandTotal, month: itm.month, name, type })
						} else {
							let place = _.findIndex(datasets1, { month: itm.month, label: itm[lead] })
							datasets1[place].data = _.sum([parseInt(datasets1[place].data), parseInt(itm.grandTotal)])
						}
					} else {
						if (_.find(datasets1, { label: itm[lead][0][lead], month: itm.month }) === undefined) {
							name = name === "week" ? itm.weekday : name
							name = type === "today" ? "Today" : name
							datasets1.push({ label: itm[lead][0][lead], data: itm.grandTotal, month: itm.month, name, type })
						} else {
							let place = _.findIndex(datasets1, { month: itm.month, label: itm[lead][0][lead] })
							datasets1[place].data = _.sum([parseInt(datasets1[place].data), parseInt(itm.grandTotal)])
						}
					}

					if (i === data.length - 1) {
						return
					}
				})
				if (done) {
					var done3 = labels.map((label) => {
						datasets1.map((item) => {
							if (item.name === label) {
								let indexPoint = _.findIndex(labels, (val) => (val === label ? val : ""))
								let sts = _.find(newData, { label: item.label })
								let stsIndex = _.findIndex(newData, { label: item.label })
								if (sts) {
									sts.data[indexPoint] = item.data
									newData[stsIndex] = sts
								} else {
									let newD = {
										label: "",
										data: [],
										backgroundColor: [],
										hoverBackgroundColor: [],
									}
									newD.data[indexPoint] = item.data
									let setBgClr = `#${randomColor()}`
									let setHbClr = `#${randomColor()}`
									let genBgClr = []
									let genHbClr = []
									for (let index = 0; index < 20; index++) {
										genBgClr.push(setBgClr)
									}
									for (let index = 0; index < 20; index++) {
										genHbClr.push(setHbClr)
									}

									newD.backgroundColor = genBgClr
									newD.hoverBackgroundColor = genHbClr
									newD.label = item.label === undefined ? "General" : item.label
									newData.push(newD)
								}
							}

							return
						})
						return
					})
					if (done3) {
						return
					}
					return
				}
			})
			if (done2) {
				callback({
					labels,
					datasets: newData,
				})
			}
		} else if (vals.type === "patients") {
			var mainData = vals.main
			var lead = vals.lead
			var labels = vals.labels
			var datasets1 = []
			var datasets2 = []
			var newData = []

			var done2 = _.forEach(mainData, (item, i) => {
				let { data, name, status, type, month } = item
				var done = _.forEach(data, (itm, i) => {
					if (lead === "doctor") {
						if (_.find(datasets1, { label: itm[lead], month: itm.month }) === undefined) {
							name = name === "week" ? itm.weekday : name
							name = type === "today" ? "Today" : name
							datasets1.push({ label: itm[lead], data: 1, month: itm.month, name, type })
						} else {
							let place = _.findIndex(datasets1, { month: itm.month, label: itm[lead] })
							datasets1[place].data = _.sum([parseInt(datasets1[place].data), parseInt(1)])
						}
					}

					if (i === data.length - 1) {
						return
					}
				})
				if (done) {
					var done3 = labels.map((label) => {
						datasets1.map((item) => {
							if (item.name === label) {
								let indexPoint = _.findIndex(labels, (val) => (val === label ? val : ""))
								let sts = _.find(newData, { label: item.label })
								let stsIndex = _.findIndex(newData, { label: item.label })
								if (sts) {
									sts.data[indexPoint] = item.data
									newData[stsIndex] = sts
								} else {
									let newD = {
										label: "",
										data: [],
										backgroundColor: [],
										hoverBackgroundColor: [],
									}
									newD.data[indexPoint] = item.data
									let setBgClr = `#${randomColor()}`
									let setHbClr = `#${randomColor()}`
									let genBgClr = []
									let genHbClr = []
									for (let index = 0; index < 20; index++) {
										genBgClr.push(setBgClr)
									}
									for (let index = 0; index < 20; index++) {
										genHbClr.push(setHbClr)
									}

									newD.backgroundColor = genBgClr
									newD.hoverBackgroundColor = genHbClr
									newD.label = item.label
									newData.push(newD)
								}
							}

							return
						})
						return
					})
					if (done3) {
						return
					}
					return
				}
			})
			if (done2) {
				callback({
					labels,
					datasets: newData,
				})
			}
		}
	}

	this.getToday = function (year) {
		let start = moment().set("year", year)
		let end = moment().set("year", year)

		return {
			type: "today",
			dates: [{ start: start.startOf("day").toString(), end: end.toString(), days: end.diff(start, "days") + 1, label: "" }],
		}
	}

	this.getThisWeekDates = function (year) {
		let start = moment().set("year", year).day(0)
		let end = moment().set("year", year)

		return {
			type: "week",
			dates: [{ start: start.toString(), end: end.toString(), days: end.diff(start, "days") + 1, label: "week" }],
		}
	}

	this.getThisMonthWeeks = function (year) {
		let genDates = [
			{ start: moment().set("year", year).startOf("month"), end: moment().set("year", year).startOf("month").endOf("week"), weekName: "week1" },
			{ start: moment().set("year", year).startOf("month").set("year", year).startOf("week").add(1, "w"), end: moment().set("year", year).startOf("month").endOf("week").add(1, "w"), weekName: "week2" },
			{ start: moment().set("year", year).startOf("month").set("year", year).startOf("week").add(2, "w"), end: moment().set("year", year).startOf("month").endOf("week").add(2, "w"), weekName: "week3" },
			{ start: moment().set("year", year).startOf("month").set("year", year).startOf("week").add(3, "w"), end: moment().set("year", year).startOf("month").endOf("week").add(3, "w"), weekName: "week4" },
			{ start: moment().set("year", year).startOf("month").set("year", year).startOf("week").add(4, "w"), end: moment().set("year", year).endOf("month"), weekName: "week5" },
		]
		if (genDates) {
			genDates = genDates.map((week, index) => {
				return {
					start: week.start.toString(),
					end: week.end.toString(),
					days: week.end.diff(week.start, "days") + 1,
					label: week.weekName,
				}
			})
			return {
				type: "month",
				dates: genDates,
			}
		}
	}

	this.getLastMonthWeeks = function (year) {
		let genDates = [
			{ start: moment().set("year", year).subtract(1, "months").startOf("month"), end: moment().set("year", year).subtract(1, "months").startOf("month").endOf("week"), weekName: "week1" },
			{ start: moment().set("year", year).subtract(1, "months").startOf("month").startOf("week").add(1, "w"), end: moment().set("year", year).subtract(1, "months").startOf("month").endOf("week").add(1, "w"), weekName: "week2" },
			{ start: moment().set("year", year).subtract(1, "months").startOf("month").startOf("week").add(2, "w"), end: moment().set("year", year).subtract(1, "months").startOf("month").endOf("week").add(2, "w"), weekName: "week3" },
			{ start: moment().set("year", year).subtract(1, "months").startOf("month").startOf("week").add(3, "w"), end: moment().set("year", year).subtract(1, "months").startOf("month").endOf("week").add(3, "w"), weekName: "week4" },
			{ start: moment().set("year", year).subtract(1, "months").startOf("month").startOf("week").add(4, "w"), end: moment().set("year", year).subtract(1, "months").endOf("month"), weekName: "week5" },
		]
		if (genDates) {
			genDates = genDates.map((week, index) => {
				return {
					start: week.start.toString(),
					end: week.end.toString(),
					days: week.end.diff(week.start, "days") + 1,
					label: week.weekName,
				}
			})
			return {
				type: "month",
				dates: genDates,
			}
		}
	}

	this.getByQuarter = function (a, b, c, year) {
		let genDates = [
			{ start: moment().set("year", year).set("month", a).startOf("month"), end: moment().set("year", year).set("month", a).endOf("month"), monthName: moment(a + 1, "M").format("MMMM") },
			{ start: moment().set("year", year).set("month", b).startOf("month"), end: moment().set("year", year).set("month", b).endOf("month"), monthName: moment(b + 1, "M").format("MMMM") },
			{ start: moment().set("year", year).set("month", c).startOf("month"), end: moment().set("year", year).set("month", c).endOf("month"), monthName: moment(c + 1, "M").format("MMMM") },
		]
		if (genDates) {
			genDates = genDates.map((month, index) => {
				return {
					start: month.start.toString(),
					end: month.end.toString(),
					days: month.end.diff(month.start, "days") + 1,
					label: month.monthName,
				}
			})
			return {
				type: a === 0 ? "1st Quarter" : a === 3 ? "2nd Quarter" : a === 6 ? "3rd Quarter" : a === 9 ? "4th Quarter" : "",
				dates: genDates,
			}
		}
	}

	this.matrixTermLabelHelper = function (value) {
		return value === "today"
			? ["Today"]
			: value === "thisweek"
			? ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"]
			: value === "thismonth"
			? ["week1", "week2", "week3", "week4", "week5"]
			: value === "lastmonth"
			? ["week1", "week2", "week3", "week4", "week5"]
			: value === "1"
			? ["January", "February", "March"]
			: value === "2"
			? ["April", "May", "June"]
			: value === "3"
			? ["July", "August", "September"]
			: ["October", "November", "December"]
	}

	this.matrixAddColors = function (value) {
		let done = value.labels.map(() => {
			value.datasets.map((item, i) => {
				item.backgroundColor.push(`#${randomColor}`)
			})
		})
		if (done) {
			return value
		}
	}

	this.processAppointmentTime = function (number) {
		if (number.includes(":") || number.includes(":")) {
			return number
		}
		let hours = parseInt(constants.DEFAULT_APPOINTMENTAPP_START_TIME[0]) + parseInt(number)

		let minutes = parseInt(constants.DEFAULT_APPOINTMENTAPP_START_TIME[1])

		minutes = minutes.toString() + "0"
		let finalTime = hours + ":" + minutes
		finalTime = finalTime.toString()
		return moment(finalTime, "HH:mm").format("LT")
	}

	this.razorPay = (options) => {
		console.log(options)
		return new window.Razorpay({
			key: constants.RAZOR_KEY_ID,
			...options,
		})
	}

	this.handleDownload = (url, filename) => {
		axios
			.get(url, {
				responseType: "blob",
			})
			.then((res) => {
				fileDownload(res.data, filename)
			})
	}

	this.getExtension = (url) => {
		return url.split(".").pop()
	}
	this.standardRange = function (stringValue) {
		if (stringValue.constructor !== String || stringValue.constructor === null || stringValue.constructor === undefined) {
			console.error("Input for standardRange function is not a string!")
			return
		}
		let data = stringValue
		let standardRange = []
		// data.split("]").map((d, i) => {
		// 	const x = d.split("[")
		data.split("|").map((d, i) => {
			const x = d.split("|")
			x[0].trim() && standardRange.push({ name: x[0].trim(), value: x[1], id: i + 1 })
		})
		console.log(standardRange)
		return standardRange
	}

	this.numToWords = function (amount) {
		var words = new Array()
		words[0] = ""
		words[1] = "One"
		words[2] = "Two"
		words[3] = "Three"
		words[4] = "Four"
		words[5] = "Five"
		words[6] = "Six"
		words[7] = "Seven"
		words[8] = "Eight"
		words[9] = "Nine"
		words[10] = "Ten"
		words[11] = "Eleven"
		words[12] = "Twelve"
		words[13] = "Thirteen"
		words[14] = "Fourteen"
		words[15] = "Fifteen"
		words[16] = "Sixteen"
		words[17] = "Seventeen"
		words[18] = "Eighteen"
		words[19] = "Nineteen"
		words[20] = "Twenty"
		words[30] = "Thirty"
		words[40] = "Forty"
		words[50] = "Fifty"
		words[60] = "Sixty"
		words[70] = "Seventy"
		words[80] = "Eighty"
		words[90] = "Ninety"
		amount = amount ? amount.toString() : ""
		var atemp = amount ? amount.split(".") : ""
		var number = atemp ? atemp[0].split(",").join("") : ""
		var n_length = number.length
		var words_string = ""
		// if (Number(amount) < 0) {
		// 	words_string += "-"
		// 	number = Math.abs(Number(number)).toString()
		// }
		if (n_length <= 9) {
			var n_array = new Array(0, 0, 0, 0, 0, 0, 0, 0, 0)
			var received_n_array = new Array()
			for (var i = 0; i < n_length; i++) {
				received_n_array[i] = number.substr(i, 1)
			}
			for (var i = 9 - n_length, j = 0; i < 9; i++, j++) {
				n_array[i] = received_n_array[j]
			}
			for (var i = 0, j = 1; i < 9; i++, j++) {
				if (i == 0 || i == 2 || i == 4 || i == 7) {
					if (n_array[i] == 1) {
						n_array[j] = 10 + parseInt(n_array[j])
						n_array[i] = 0
					}
				}
			}
			let value = ""
			for (var i = 0; i < 9; i++) {
				if (i == 0 || i == 2 || i == 4 || i == 7) {
					value = n_array[i] * 10
				} else {
					value = n_array[i]
				}
				if (value != 0) {
					words_string += words[value] + " "
				}
				if ((i == 1 && value != 0) || (i == 0 && value != 0 && n_array[i + 1] == 0)) {
					words_string += "Crores "
				}
				if ((i == 3 && value != 0) || (i == 2 && value != 0 && n_array[i + 1] == 0)) {
					words_string += "Lakhs "
				}
				if ((i == 5 && value != 0) || (i == 4 && value != 0 && n_array[i + 1] == 0)) {
					words_string += "Thousand "
				}
				if (i == 6 && value != 0 && n_array[i + 1] != 0 && n_array[i + 2] != 0) {
					words_string += "Hundred and "
				} else if (i == 6 && value != 0) {
					words_string += "Hundred "
				}
			}
			if (words_string.startsWith("undefined")) {
				words_string = words_string.replace("undefined", "-")
				words_string = words_string.trim() + " Rupees Only"
			} else {
				words_string = words_string.trim() + " Rupees Only"
			}
			return words_string
		}
		// return words_string + "Rupees Only"
	}

	this.convertStandardRangeToArray = function (range) {
		console.log("reange ====>", range)
		// const noWhiteSpaceData = range.replace(/\s+/g, "")
		// const splitData = noWhiteSpaceData.split("]").filter((x) => x)
		const splitData = range.split("|").filter((x) => x)
		let newArray = []
		console.log("splitDAta==", splitData)
		splitData.forEach((x) => {
			// let newObj = {label: x.charAt(0), value: x.slice(2)}
			let newObj = { value: x.slice(0) }
			newArray = [...newArray, newObj]
		})
		return newArray
	}

	this.parseIntHelper = function (data) {
		const isNan = Number.isNaN(data)
		if (isNan) {
			return 0
		} else {
			return parseInt(data)
		}
	}
	this.userDiscount = function (unitCost, discount, data, type) {
		if (type === "R") {
			if (data === 0 || data === undefined || data === "") {
				return true
			} else if (data !== 0 || data !== undefined) {
				let rupee = (discount / unitCost) * 100
				let per = data - rupee

				if (per >= 0) {
					return true
				} else {
					return false
				}
			}
		}
		if (type === "P") {
			if (data === 0 || data === undefined || data === "") {
				return true
			} else if (data !== 0 || data !== undefined) {
				let per = data - discount

				if (per >= 0) {
					return true
				} else {
					return false
				}
			}
		}
	}
	this.processGQlSchema = (functions) => {
		// list of querytype fileds
		const queryTypeFields = functions.map((fun_c) => {
			console.log(fun_c)
			const queryTypeField = {
				function: fun_c.name,
				args: fun_c.args.map((arg) => {
					return arg.name
				}),
			}
			if (fun_c.type.ofType) {
				queryTypeField.response = fun_c.type.ofType.fields.map((field) => {
					// if field.type.ofType is not null then get fields
					if (field.type.ofType) {
						return field.name
					}
					return field.name
				})
			}
			return queryTypeField
		})
		return queryTypeFields
	}

	this.processQuery = (data) => {
		console.log({ data })
		let query = `query ${data.query_name}(${Object.keys(data.root_query_variables)
			.map((key) => {
				return `$${key}:String!`
			})
			.join(",")}){${data.root_query}(${Object.keys(data.root_query_variables)
			.map((key) => {
				return `${key}:$${key}`
			})
			.join(",")}){${this.processResponseInput(data.response)}}}`
		return query
	}

	this.getQueryKeys = (data) => {
		return data.response
	}

	this.setDefaultVariables = (defaultVariables, variables) => {
		let newVariables = {}
		Object.keys(variables).forEach((key) => {
			if (variables[key] === undefined || variables[key] === null || variables[key] === "") {
				newVariables[key] = defaultVariables[key]
			} else {
				newVariables[key] = variables[key]
			}
		})

		return { ...defaultVariables, ...newVariables }
	}

	this.processResponseInput = (data) => {
		let respo = {}
		data.forEach((item) => {
			let arr = item.split(".")
			let temp = respo
			arr.forEach((item, index) => {
				if (index === arr.length - 1) {
					temp[item] = {}
				} else {
					if (!temp[item]) {
						temp[item] = {}
					}
					temp = temp[item]
				}
			})
		})
		return this.convertToQuery(respo)
	}

	this.convertToQuery = (respo) => {
		let query = ""
		Object.keys(respo).forEach((key) => {
			if (Object.keys(respo[key]).length > 0) {
				query += key + " { " + this.convertToQuery(respo[key]) + "} "
			} else {
				query += key + " "
			}
		})
		return query
	}

	this.processResponse = (data, keys) => {
		let flattenedData = []
		data.forEach((item) => {
			let obj = {}
			keys.forEach((key) => {
				let value = _.get(item, key)
				if (value) {
					obj[key] = value
				} else {
					obj[key] = ""
				}
			})
			flattenedData.push(obj)
		})
		return flattenedData
	}

	this.formatIndianCurrency = (number) => {
		const formatter = new Intl.NumberFormat("en-IN", {
			style: "currency",
			currency: "INR",
		})

		return formatter.format(number)
	}

	this.formatIndianCurrencyToNumber = (currency) => {
		let number = currency.replace(/₹\s?|(,*)/g, '');
		return parseFloat(number);
	}
}

helpers = new helpers()

export default helpers
