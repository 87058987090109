import update from 'react-addons-update'
import { EXPIRE_POLICY_DATA, USER_INFO } from '../actions/users/types'

const InitialExpiryPolicyData = [
	{
		data: {
			policy_status: false,
			policy_user_data: null,
		},
	},
]

export const getUserInfo = (state = [], payload) => {
	switch (payload.type) {
		case USER_INFO:
			return update(state, {
				0: { $set: payload },
			})
			break
		default:
			return state
	}
}

export const getExpirePolicyData = (state = InitialExpiryPolicyData, payload) => {
	switch (payload.type) {
		case EXPIRE_POLICY_DATA:
			return update(state, {
				0: { $set: payload },
			})
			break
		default:
			return state
	}
}
