import "filepond/dist/filepond.min.css"
import _ from "lodash"
import React, { Component, Fragment, useState } from "react"
import { FilePond } from "react-filepond"
import { DropdownList } from "react-widgets"
import "react-widgets/dist/css/react-widgets.css"
import Multiselect from "react-widgets/lib/Multiselect"
import EventEmitter from "react-native-eventemitter"
import { Input, Label } from "reactstrap"
import { withStyles } from "@material-ui/core/styles"
import { green } from "@material-ui/core/colors"
import FormGroup from "@material-ui/core/FormGroup"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import Checkbox from "@material-ui/core/Checkbox"
import UploadFileS3 from "../attachments/UploadFileS3"
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank"
import CheckBoxIcon from "@material-ui/icons/CheckBox"
import Favorite from "@material-ui/icons/Favorite"
import FavoriteBorder from "@material-ui/icons/FavoriteBorder"
import Webcam from "react-webcam"
class Sections extends Component {
	render() {
		return <div>Sections</div>
	}
}

// export Sections.IM_Textarea

export default Sections

const randString = () => {
	var emptyString = ""
	var alphabet = "abcdefghijklmnopqrstuvwxyz"
	while (emptyString.length < 8) {
		emptyString += alphabet[Math.floor(Math.random() * alphabet.length)]
	}
	return emptyString
}

const IM_Label = (props) => {
	const { id, title } = props
	return (
		<Label for={id}>
			<span style={{ fontSize: "20px" }}>
				<b>{props.children}</b>
			</span>
		</Label>
	)
}
const MultiCheckBox = (props) => {
	const { onChange, Items } = props
	return (
		<FormGroup row>
			{Items && Items.map((item, i) => <FormControlLabel key={i} control={<Checkbox checked={item.value} onChange={onChange} name={[item.item]} color="primary" />} label={[item.label]} />)}
			{/* <FormControlLabel control={<Checkbox checked={state.checkedA} onChange={handleChange} name="checkedA" />} label="Secondary" />
			<FormControlLabel control={<Checkbox checked={state.checkedB} onChange={handleChange} name="checkedB" color="primary" />} label="Primary" />
			<FormControlLabel control={<Checkbox name="checkedC" />} label="Uncontrolled" />
			<FormControlLabel disabled control={<Checkbox name="checkedD" />} label="Disabled" />
			<FormControlLabel disabled control={<Checkbox checked name="checkedE" />} label="Disabled" />
			<FormControlLabel control={<Checkbox checked={state.checkedF} onChange={handleChange} name="checkedF" indeterminate />} label="Indeterminate" />
			<FormControlLabel control={<Checkbox checked={state.checkedG} onChange={handleChange} name="checkedG" />} label="Custom color" />
			<FormControlLabel control={<Checkbox icon={<FavoriteBorder />} checkedIcon={<Favorite />} name="checkedH" />} label="Custom icon" />
			<FormControlLabel control={<Checkbox icon={<CheckBoxOutlineBlankIcon fontSize="small" />} checkedIcon={<CheckBoxIcon fontSize="small" />} name="checkedI" />} label="Custom size" /> */}
		</FormGroup>
	)
}

const IM_TextArea = (props) => {
	const { rows, id, name, value,defaultValue, onChange, onClick, onBlur, disable } = props
	return (
		<Fragment>
			<textarea className="form-control" id={id} rows={rows} value={value} defaultValue={defaultValue} name={name} disabled={disable} onChange={onChange}></textarea>
		</Fragment>
	)
}

const IM_TextBox = (props) => {
	const { id, name, value,defaultValue, onChange, onClick, onBlur } = props

	return (
		<Fragment>
			<input type="text" className="form-control" id={id} value={value} defaultValue={defaultValue} name={name} onChange={onChange} />
		</Fragment>
	)
}

const IM_TextBox_2 = (props) => {
	let { id, name, value,defaultValue, onChange, onClick, onBlur } = props
	function change(e) {
		console.log(e)
		value = e.target.value
	}
	return (
		<Fragment>
			<input type="text" className="form-control" id={id} value={value} defaultValue={defaultValue} name={name} onChange={change} onBlur={onChange} />
		</Fragment>
	)
}

const IM_DropDown = (props) => {
	const { id, name, value, onChange, items, type, className, width } = props

	return (
		<Fragment>
			<Input className={className} type="select" style={{ width: width || "350px" }} placeholder="Select " containerclassname={`multi-select-class`} id={id} value={value} name={name} onChange={onChange}>
				<option value="">select</option>
				{type === "split" && items != undefined ? items.split(",").map((item, i) => <option key={i}>{item}</option>) : items != undefined && items.length > 0 ? items.map((item, i) => <option key={i}>{item}</option>) : ""}
			</Input>
		</Fragment>
	)
}

const IM_DropDown_Adv = (props) => {
	const { id, name, onChange, items, viewValues, defaultValue, type } = props
	let new_Items = [];
	type === "split" && items != undefined ? items.split(",").map((item, i) => new_Items.push(item)) : new_Items = items;
	return (
		<Fragment>
			
			{items ? (
				<DropdownList
					defaultValue={defaultValue}
					filter='contains'
					style={{ flex: "1" }}
					id={id}
					name={name}
					data={new_Items}
					textField={(item) => (item != undefined ? (viewValues.length > 1 ? `${item[viewValues[1]]} - ${item[viewValues[0]]}` : viewValues.length > 0 ? `${item[viewValues[0]]}` : item) : "")}
					onChange={onChange}
					valueComponent={({ item }) => <span>{item != undefined ? (viewValues.length > 1 ? `${item[viewValues[1]]} - ${item[viewValues[0]]}` : viewValues.length > 0 ? `${item[viewValues[0]]}` :item) : ""}</span>}
				/>
			) : (
				""
			)}
		</Fragment>
	)
}

const IM_DropDown_Search_basic = (props) => {
	const { id, name, onChange, items, viewValues, defaultValue, type } = props
	let new_Items = [];
	// type === "split" && items != undefined ? items.split(",").map((item, i) => new_Items.push(item)) : new_Items = items;
	// type "split, array, object" 
	type === "split" && items!= undefined ? items.split(",").map((item, i) => new_Items.push(item)) : type === "object" && items!= undefined ? items && items.map((item, i) => new_Items.push(item.service)) : type === "array" && items!= undefined ? items && items.map((item, i) => new_Items.push(item)) : new_Items = items;

	return (
		<Fragment>
			
			{items ? (
				<DropdownList
					value={defaultValue}
					filter='contains'
					style={{ flex: "1" }}
					id={id}
					name={name}
					data={new_Items}
					onChange={onChange}
				/>
			) : (
				""
			)}
		</Fragment>
	)
}

const IM_MultiSelect = (props) => {
	const { id, name, value, onChange, items, type, width } = props

	return (
		<Fragment>
			<Multiselect style={{ flex: "1" }} placeholder="" containerClassName="multi-select-class" data={type === "split" && items != undefined ? items.split(",") : items} name={name} valueField="role" textField="role" value={value} onChange={onChange} />
		</Fragment>
	)
}

const IM_Date = (props) => {
	const { id, name, value, onChange, min } = props

	return (
		<Fragment>
			<Input type="date" value={value} onChange={onChange} name={name} min={min} />
		</Fragment>
	)
}

const IM_Time = (props) => {
	const { id, name, value, onChange, min } = props

	return (
		<Fragment>
			<Input type="time" value={value} onChange={onChange} name={name} min={min} />
		</Fragment>
	)
}

const IM_RadioGroup = (props) => {
	const { id, name, value, onChange, items, type, className } = props
	return (
		<div className={className ? className : ""} style={{ display: "flex" }}>
			{type === "split"
				? items != undefined &&
				  items.split(",").map((item, i) => (
						<div style={{ margin: "10px", display: "flex" }}>
							<input type="radio" id={i} name={name} value={item} checked={item === value ? true : ""} onChange={onChange} style={{ margin: "10px" }} />
							<label for={i} style={{ margin: "10px" }}>
								{item}
							</label>
						</div>
				  ))
				: items != undefined &&
				  items.map((item, i) => (
						<div style={{ margin: "10px", display: "flex" }}>
							<input type="radio" id={i} name={name} value={item} checked={item === value ? true : ""} onChange={onChange} />
							<label for={i}>{item}</label>
						</div>
				  ))}
		</div>
	)
}

const IM_RadioGroupTR = (props) => {
	const { id, name, value, onChange, items, type } = props
	return (
		<Fragment>
			{type === "split"
				? items != undefined &&
				  items.split(",").map((item, i) => (
						<td>
							<input type="radio" id={i} name={name} value={item} checked={item === value ? true : ""} onChange={onChange} style={{ margin: "10px" }} />
							<label for={i} style={{ margin: "10px" }}>
								{item}
							</label>
						</td>
				  ))
				: items != undefined &&
				  items.map((item, i) => (
						<td>
							<center>
								<input type="radio" id={i} name={name} value={item} checked={item === value ? true : ""} onChange={onChange} />
							</center>
						</td>
				  ))}
		</Fragment>
	)
}

const IM_CheckBox = (props) => {
	const { id, name, value, onChange, onClick, onBlur, items, type, status } = props
	if (status == true) {
		return (
			<div style={{ width: "100%" }}>
				{type === "split"
					? items != undefined &&
					  items.split(",").map((item, i) => (
							<div style={{ display: "flex", alignItems: "center", margin: "5px" }}>
								<input key={i} type="checkbox" id={id} checked={value && value.length >= 0 && _.includes(value, item) ? true : false} name={item} onChange={() => onChange(value && value.length >= 0 ? (_.includes(value, item) ? _.filter(value, (current) => current !== item) : [...value, item]) : [item])} />
								<span style={{ marginLeft: "5px" }}>{item}</span>
							</div>
					  ))
					: items != undefined && items.map((item, i) => <input type="checkbox" id={id} value={value} name={name} checked={item === value ? true : ""} onChange={onChange} />)}
			</div>
		)
	} else {
		return (
			<div style={{ width: "100%" }}>
				{type === "split"
					? items != undefined &&
					  items.split(",").map((item, i) => (
							<div style={{ display: "flex", alignItems: "center", margin: "5px" }}>
								<input key={i} type="checkbox" id={id} checked={value && value.length >= 0 && _.includes(value, item) ? true : false} name={item} />
								<span style={{ marginLeft: "5px" }}>{item}</span>
							</div>
					  ))
					: items != undefined && items.map((item, i) => <input type="checkbox" id={id} value={value} name={name} checked={item === value ? true : ""} onChange={onChange} />)}
			</div>
		)
	}
}

const IM_FileUploadS3 = (props) => {
	const {checkin_id,client_id, files, callbackFiles,callback,callbackShow} = props
	return (
		<UploadFileS3
			type="custom_form_files"
			checkin_id={checkin_id}
			client_id={client_id}
			randString={randString}
			allowMultiple={true}
			callbackFiles={(filess) => callbackFiles({ files: JSON.parse(filess) })}
			// callback={(files) => callback(files)}
			callbackShow={(val) => callbackShow({ explorerShowMulti: val })}
		/>
	)
}

const IM_FileUpload = (props) => {
	const { server, name, oninit, onupdatefiles, allowMultiple, pond } = props
	// EventEmitter.on("capture", (value) => {
	// 	console.log(pond)
	// 	// var block = value.split(";");
	// 	// var contentType = block[0].split(":")[1];
	// 	// pond.addFile(value);
	// 	// const data = { hello: 'world' };
	// 	// const blob = new Blob([JSON.stringify(data, null, 2)], {
	// 	// 	type: contentType
	// 	// });
	// 	// pond.addFile(blob);
	// })

	return (
		<Fragment>
			<FilePond
				ref={(ref) => pond(ref)}
				acceptedFileTypes={["image/png", "image/jpeg"]}
				labelFileTypeNotAllowed={"Upload only PNG or JPEG file."}
				dropValidation={true}
				maxFileSize="2MB"
				maxFiles={5}
				allowRemove={false}
				labelIdle="Upload file <span class='filepond--label-action'> Browse </span>"
				server={server}
				labelMaxTotalFileSize={"Total file size should be lesser than 10MB."}
				allowFileSizeValidation={true}
				maxTotalFileSize={10485760}
				allowMultiple={allowMultiple}
				// allowReplace={true}
				// allowRemove={true}
				name={name}
				oninit={oninit}
				onupdatefiles={onupdatefiles}
			/>
		</Fragment>
	)
}
const IM_Csv_FileUpload = (props) => {
	const { server, name, oninit, onupdatefiles, allowMultiple, pond } = props
	// EventEmitter.on("capture", (value) => {
	// 	console.log(pond)
	// 	// var block = value.split(";");
	// 	// var contentType = block[0].split(":")[1];
	// 	// pond.addFile(value);
	// 	// const data = { hello: 'world' };
	// 	// const blob = new Blob([JSON.stringify(data, null, 2)], {
	// 	// 	type: contentType
	// 	// });
	// 	// pond.addFile(blob);
	// })
	return (
		<Fragment>
			<FilePond
				ref={(ref) => pond(ref)}
				acceptedFileTypes={["text/csv"]}
				labelFileTypeNotAllowed={"Upload only CSV file."}
				dropValidation={true}
				maxFileSize="2MB"
				maxFiles={5}
				allowRemove={false}
				labelIdle="Upload file <span class='filepond--label-action'> Browse </span>"
				server={server}
				labelMaxTotalFileSize={"Total file size should be lesser than 10MB."}
				allowFileSizeValidation={true}
				maxTotalFileSize={10485760}
				allowMultiple={allowMultiple}
				// allowReplace={true}
				// allowRemove={true}
				name={name}
				oninit={oninit}
				onupdatefiles={onupdatefiles}
			/>
		</Fragment>
	)
}
const IM_WebcamCapture = (props) => {
	var { onChange, videoConstraints } = props
	const [videoConstraintsValue, setVideoConstraintsValue] = useState(videoConstraints)
	const webcamRef = React.useRef(null)

	const b64toBlob = (b64Data, contentType, sliceSize) => {
		contentType = contentType || ""
		sliceSize = sliceSize || 512

		var byteCharacters = atob(b64Data)
		var byteArrays = []

		for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
			var slice = byteCharacters.slice(offset, offset + sliceSize)

			var byteNumbers = new Array(slice.length)
			for (var i = 0; i < slice.length; i++) {
				byteNumbers[i] = slice.charCodeAt(i)
			}

			var byteArray = new Uint8Array(byteNumbers)

			byteArrays.push(byteArray)
		}

		var blob = new Blob(byteArrays, { type: contentType })
		return blob
	}

	const capture = React.useCallback(() => {
		const imageSrc = webcamRef.current.getScreenshot()
		// var block = imageSrc.split(";");
		// var contentType = block[0].split(":")[1];
		// var realData = block[1].split(",")[1];
		// var blob = b64toBlob(realData, contentType);
		onChange(imageSrc)
	}, [webcamRef])

	const ChangeFacing = () => {
		if (videoConstraints.facingMode !== "user") {
			videoConstraints.facingMode = "user"
			setVideoConstraintsValue({ ...videoConstraints, facingMode: "user" })
		} else {
			videoConstraints.facingMode = { exact: "environment" }
			setVideoConstraintsValue({ ...videoConstraints, facingMode: { exact: "environment" } })
		}
	}

	return (
		<div style={{ border: "1px solid rgb(47, 59, 73)", borderRadius: "10px", padding: "20px", display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
			<Webcam audio={false} ref={webcamRef} screenshotFormat="image/jpeg" width="100%" videoConstraints={videoConstraintsValue} />
			<div style={{ position: "absolute", color: "#00000094", cursor: "pointer" }} onClick={capture}>
				<i class="fa fa-camera fa-2x"></i>{" "}
			</div>
			<div style={{ position: "absolute", color: "#00000094", bottom: "50px", right: "50px", cursor: "pointer" }} onClick={ChangeFacing}>
				<i class="fa fa-retweet fa-2x"></i>
			</div>
		</div>
	)
}

export { IM_Label, MultiCheckBox, IM_TextArea, IM_TextBox, IM_TextBox_2, IM_DropDown, IM_DropDown_Adv, IM_DropDown_Search_basic, IM_MultiSelect, IM_RadioGroup, IM_RadioGroupTR, IM_CheckBox,IM_FileUploadS3, IM_FileUpload,IM_Csv_FileUpload, IM_WebcamCapture, IM_Date, IM_Time }
