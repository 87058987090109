/* eslint-disable */
/* eslint-disable */

// eslint-disable-next-line
import moment from 'moment'
import React, { Component } from 'react'
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import axios2 from '../../../services/axios'
var axios = axios2.getAxios()


export default class OnlinePayform extends Component {
	constructor(props) {
		super(props)
		this.now = moment()
		this.toggle = this.toggle.bind(this)
		this.handleToggle = this.handleToggle.bind(this)
	}
	toggle() {
		this.props.closeModal()
	}
	handleToggle(e) {
		e.preventDefault()
		this.props.submitModal()
	}

	render() {
		return (
			<Modal backdrop={this.props.backdrop} isOpen={this.props.modalStatus} toggle={this.toggle} className={this.props.className}>
				<ModalHeader style={{ color: 'gray' }} toggle={this.toggle}>
					Appointment Info
				</ModalHeader>
				<form id="userform" autoComplete="off" className="form-horizontal" onSubmit={this.handleToggle} role="form">
					<ModalBody style={{ color: 'gray' }}>Do you want to book an appointment?</ModalBody>
					<ModalFooter>
						<Button color="primary" type="submit">
							Yes
						</Button>
						<Button color="secondary" onClick={this.toggle}>
							No
						</Button>
					</ModalFooter>
				</form>
			</Modal>
		)
	}
}
