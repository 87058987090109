/* eslint-disable */

// Styles
// Import Font Awesome Icons Set
import "font-awesome/css/font-awesome.min.css"
import { createBrowserHistory } from "history"
import React, { Component, Suspense } from "react"
import AsyncSplitter from "react-code-splitting"
import {createRoot } from "react-dom/client"
import { Provider } from "react-redux"
import { Route, Router, Switch } from "react-router-dom"
import { BarLoader } from "react-spinners"	
// Import Simple Line Icons Set
import "simple-line-icons/css/simple-line-icons.css"
// Import Main styles for this application
import "../src/assets/scss/style.scss"
import "./styles/tailwind.generated.css";
import { saveState } from "./localStorage"
import * as serviceWorker from "./serviceWorker"
import Store from "./store"
import CheckOutForm from "./views/Payments/CheckOutForm"
const OnlineDoctors = React.lazy(() => import("./views/Patients/external/OnlineDoctors"))
const DoctorProfile = React.lazy(() => import("./views/Doctors/Profile"))
const Login = React.lazy(() => import("./views/Login/Login"))
const Full = React.lazy(() => import("./containers/Full/"))
const SuccessPage = React.lazy(() => import("./views/successpage/successpage.js"))
const Verify = React.lazy(() => import("./views/Login/Verify"))
const Feedback = React.lazy(() => import("./views/Patients/external/Feedback"))
const AppointmentApp = React.lazy(() => import("./views/Patients/external/AppointmentApp"))
const ScheduleSuccess = React.lazy(() => import("./views/Patients/external/ScheduleSuccess"))

// Store
const StoreInstance = Store()

StoreInstance.subscribe(() => {
	saveState(StoreInstance.getState())
})

const Password = () => <AsyncSplitter load={import("./views/Password/")} />

const ChangePassword = () => <AsyncSplitter load={import("./views/Password/ChangePassword")} />

const history = createBrowserHistory()
export default history

class PageNotfoundComponent extends Component {
	constructor(props) {
		super(props)
	}
	render() {
		return (
			<div>
				<h1>404 Page not found</h1>
			</div>
		)
	}
}
class PagePermissionError extends Component {
	constructor(props) {
		super(props)
	}
	render() {
		return (
			<div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
				<h1 style={{ color: "white" }}>Invalid URL</h1>
			</div>
		)
	}
}


const root = createRoot(document.getElementById("root"))


root.render(
	<Provider store={StoreInstance}>
		<Router history={history}>
			<Suspense fallback={<BarLoader size={30} width="100%" color={"#ddd"} loading={true} />}>
				<Switch>
					<Route exact path="/feedback" name="Login Page" component={Feedback} />
					<Route exact path="/checkout" name="Login Page" component={CheckOutForm} />
					<Route path="/schedule/:facility_id/:sha_key" name="AppointmentApp" component={AppointmentApp} />
					<Route path="/our-doctors/:facility_id" name="AppointmentApp" component={OnlineDoctors} />
					<Route path="/doc-profile/:doctor_id" name="DoctorProfile" component={DoctorProfile} />
					<Route path="/schedule_success/:facility_id/:sha_key" name="ScheduleSuccess" component={ScheduleSuccess} />
					<Route exact path="/login" name="Login Page" component={Login} />
					<Route path="/login/:token?/:ssoinfo?" component={Login} />
					<Route exact path="/success" name="Success Page" component={SuccessPage} />
					<Route exact path="/urlverify" name="Verify Page" component={Verify} />
					<Route exact path="/urlverify/:token" component={Verify} />
					<Route exact path="/setpassword" name="Password" component={Password} />
					<Route path="/changepasswordexpiry" name="ChangePassword" component={ChangePassword} />
					<Route path="/setpassword/:email/:token" component={Password} />
					<Route path="/404" name="404" component={PageNotfoundComponent} />
					<Route path="/PagePermissionError" name="PagePermissionError" component={PagePermissionError} />
					<Route path="/" name="Home" component={Full} />
				</Switch>
			</Suspense>
		</Router>
	</Provider>)

serviceWorker.unregister()
