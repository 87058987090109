import { applyMiddleware, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';
import { loadState } from './localStorage';
import rootReducer from './redux/reducers';
const persistedState = loadState();


export default function configureStore() {
  return createStore(
    rootReducer,persistedState,composeWithDevTools(applyMiddleware(thunk))
  );
}