// eslint-disable-next-line
import axios from "axios"
import React, { Component, useState } from "react"
import EventEmitter from "react-native-eventemitter"
import { Alert, Button, Card, CardBody, CardHeader, Col, Input, InputGroup, InputGroupAddon, Row, Badge } from "reactstrap"
import { IM_RadioGroup } from "../../Asts/Sections/Sections"
import moment from "moment"
import { DropdownList } from "react-widgets"
import helpers from "../../../helpers/helpers"
import OnlinePayform from "../modals/OnlinePayform"
import NotificationSystem from "react-notification-system"
import apiHandler from "../../../handlers/apihandler"
import CardsList from "../../../ui-components/CardsList"
import config from "../../../config/config.js"
import formValidations from "../../../config/formValidations"
import history from "../../../index"
import Logo from "../../../assets/img/logo_sidebar.png"
import "react-widgets/dist/css/react-widgets.css"
import "./OnlineDoctorsAsts/style.css"
var $ = require("jquery")
var shuffle = (d) => {
	if (d) {
		for (var c = d.length - 1; c > 0; c--) {
			var b = Math.floor(Math.random() * (c + 1))
			var a = d[c]
			d[c] = d[b]
			d[b] = a
		}
		return d
	}
}
var sha1 = require("sha1")
class OnlineDoctors extends Component {
	constructor(props, context) {
		super(props, context)

		this.state = {
			unAuthorizedPage: false,
			showAppointmentModel: false,
			doctors: [],
			client_info: {},
			show_side: false,
			//reg_id:"",
			backdrop: true,
			keyboard: true,
			PayModal: false,
			payData:{}
		}
		this.notificationSystem = null
		this.handleOpenAppointment = this.handleOpenAppointment.bind(this)
		this.handleDoctorAppointment = this.handleDoctorAppointment.bind(this)
		this.successPay = this.successPay.bind(this)
		this.client_info = JSON.parse(localStorage.getItem("client_info"))
		this.rzp = null
		
	}

	componentWillMount() {
		this._notificationSystem = this.refs.notificationSystem
		document.getElementById("loader").style.display = "block"
		this.getDoctors(this.props.match.params.facility_id, (item) => {
			console.log(item)
			if (item.status !== 0) {
				this.setState({
					unAuthorizedPage: true,
				})
			}
			this.setState({ doctors: shuffle(item.doctors) })
			this._notificationSystem = this.refs.notificationSystem

			document.getElementById("loader").style.display = "none"
		})
		this.getClientDetails(this.props.match.params.facility_id, (client_info) => this.setState({ client_info: client_info.res }))
		
	}
	componentDidMount() {}

	handleOpenAppointment() {
		this.setState({
			showAppointmentModel: !this.state.showAppointmentModel,
		})
	}

	handleDoctorAppointment(data) {
		document.getElementById("loader").style.display = "block"
		var { appointmentFee } = data
		let dtt = {
			amount: `${appointmentFee}00`,
			currency: "INR",
			receipt: `Receipt # ${Math.floor(Date.now() / 1000)}`,
		}
		axios
			.post(config.apiUrl + "/pay/gtw/rz_crt", dtt)
			.then((response) => {
				console.log(response)
				if(response.data.status == "created"){
					document.getElementById("loader").style.display = "none"
					this.handleSubmit(data,response.data)
				}
			})
			.catch((err) => {
				console.log(err)
			})
	}

	handleSubmit(data,payData) { 
		var  facility_id = this.props.match.params.facility_id
	    var patient_name = data.firstName + " " + data.lastName
		this.setState({
			appointmentData: data,
			payData: { ...payData, handler: (response) => this.successPay },
		})
		this.rzp = helpers.razorPay({ ...payData, handler: (response) => this.successPay(response.razorpay_payment_id) , "prefill": {
			"contact": data.phone,
			"email": data.email,
		}})
		this.rzp.on('payment.failed', async function (response){
			if(response && response.error){
			await axios.post(config.apiUrl + "/pay/gtw/rz_payment",{payment_id : response.error.metadata.payment_id,facility_id:facility_id,patient_name:patient_name}).then((res)=>{
				if(res){
					console.log("transaction failed")
				}
			  })
			}
		})
		this.rzp.open()
		
	}

	successPay(payment_id){
		let facility_id = this.props.match.params.facility_id
	 	var data = this.state.appointmentData
	    var patient_name = data.firstName + " " + data.lastName
		
		if (facility_id === undefined || facility_id === "") {
			alert("we need to know the hospital to set the appoitnment")
			return
		}
		if(payment_id){
		axios.post(config.apiUrl + "/pay/gtw/rz_payment",{payment_id : payment_id,facility_id:this.props.match.params.facility_id,patient_name:patient_name}).then((res)=>{
			if(res){
				this.modalCallback(this.state.appointmentData)
			}
		  })
		}	
	}

	
	modalCallback(data) {
		let facility_id = this.props.match.params.facility_id

		if (facility_id === undefined || facility_id === "") {
			alert("we need to know the hospital to set the appoitnment")
			return
		}

		this.state.appointmentSlot = "9:00 AM"
		this.setState({ confirmationModalOpen: false })
		let newAppointment = {
			// client_id : this.client_info.data._id,
			facility_id: facility_id,
			patient_first_name: data.firstName,
			patient_last_name: data.lastName,
			patient_email: data.email,
			patient_phone_number: data.phone,
			doctor_id: data.doctor_id,
			doctor_name: data.doctor_name,
			in_date: data.date,
			in_time: this.state.appointmentSlot,
			status: "Active",
			created_by: "epatient",
			updated_by: "epatient",
			payment: "paid",
			appointmentFee: data.appointmentFee,
			notes : data.check_up
		}
		if (this.state.reg_id !== null) {
			newAppointment.reg_id = data.reg_id
			newAppointment.patient_reg_id = data.reg_id
		}
		axios
			.post(config.apiUrl + "/hospital/externalScheduleAppointment", newAppointment)
			.then((response) => {
				//
				if (response.data.status === 0) {
					this._notificationSystem.addNotification({
						title: "MEDWORKS",
						message: "Appointment succesfully schedule!",
						level: "success",
						position: "tr",
					})
				} else {
					this._notificationSystem.addNotification({
						title: "MEDWORKS",
						message: response.data.errorMessage,
						level: "warning",
						position: "tr",
					})
				}
			})
			.catch((err) => {
				console.log(err)
			})
	}

	getDoctors(facility_id, callback) {
		axios.get(config.apiUrl + "/hospital/getDoctorsWithFacilityIDAndType/" + facility_id).then((response) => {
			if (response.data) {
				setTimeout(() => {
					callback(response.data)
				}, 2000)
			}
		})
	}

	getClientDetails(facility_id, callback) {
		axios.post(config.apiUrl + "/client/getClient-byId", { facility_id }).then((response) => {
			console.log(response)
			callback(response.data)
		})
	}

	render() {
		return (
			<div style={{ margin: "0px" }}>
				<NotificationSystem ref="notificationSystem" />
				{this.state.PayModal == true && (
					<OnlinePayform
						modalStatus={this.state.PayModal}
						backdrop={this.state.backdrop}
						closeModal={() => {
							this.setState({ PayModal: false, appointmentData: [] })
						}}
						submitModal={() => {
							this.modalCallback(this.state.appointmentData)
							this.setState({ PayModal: false })
						}}
					/>
				)}
				{this.state.doctors && this.state.doctors.length > 0 ? (
					<>
						<div style={{ height: "100px", display: "flex", justifyContent: "flex-start", alignItems: "center", backgroundColor: "#3d4d60" }}>
							<img src={Logo} height="100px" />
							<div style={{ color: "white" }}>
								<h2>{this.state.client_info ? this.state.client_info.client_name : ""}</h2>
								<div style={{ fontSize: "0.9em", color: "#ddd" }}>{this.state.client_info ? this.state.client_info.client_desc : ""}</div>
							</div>
							<div style={{ alignItems: "center", textAlign: "right", flex: "1" }}>{/* <span style={{padding:"10px", color:"white", fontSize:"1.3em", borderLeft:"3px solid #ddd"}}>Tele Med</span> */}</div>
						</div>
						<div style={{ widows: "100%", backgroundColor: "#fff", margin: "0px 0px 10px 0px", display: "flex", justifyContent: "space-between", padding: "20px" }}>
							<div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
								<div>Availabity</div>
								<DropdownList style={{ width: "200px", left: "40px", marginRight: "100px" }} filter id="doctors" data={["Today", "Tomorrow", "Next 7 days"]} onChange="" />
							</div>
							<img style={{ height: "50px" }} src={this.state.client_info.client_logo_url ? this.state.client_info.client_logo_url : ""} />
						</div>
						<div style={{ display: "grid", gridTemplateColumns: "1200px auto auto auto", height: "100vh" }}>
							<ListItems style={{ gridColumnStart: 1, gridColumnEnd: 2, height: "100%", backgroundColor: "#fff", padding: "20px" }} doctors={this.state.doctors} cbd={this.handleDoctorAppointment} />
							<div style={{ gridColumnStart: 2, gridColumnEnd: 5, height: "100%", backgroundColor: "#fff", borderLeft: "1px solid #eee", padding: "20px" }}>
								<div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", width: "100%" }}>
									<div style={{ textAlign: "center", fontSize: "1.5em" }}>
										For consultation
										<br /> please schedule your appoinment.
										<br /> <span style={{ fontSize: "0.8em" }}>our doctors are ready to help</span>
									</div>
									<Button className="button_clr" style={{ width: "70%", margin: "10px", padding: "10px", fontSize: "1em" }} onClick={() => this.setState({ show_side: !this.state.show_side })}>
										Book your video consultation
									</Button>
								</div>
								{this.state.show_side && <Appointment class="appointment" side={true} cbd={this.props.cbd} close={(val) => this.setState({ show_side: val })} />}
							</div>
						</div>
					</>
				) : (
					<div style={{ height: "100vh" }}>
						<div style={{ height: "100px", display: "flex", justifyContent: "flex-start", alignItems: "center", backgroundColor: "#3d4d60" }}>
							<img src={Logo} height="100px" />
							<div style={{ color: "white" }}>
								<h2>{this.state.client_info ? this.state.client_info.client_name : ""}</h2>
								<div style={{ fontSize: "0.9em", color: "#ddd" }}>{this.state.client_info ? this.state.client_info.client_desc : ""}</div>
							</div>
							<div style={{ alignItems: "center", textAlign: "right", flex: "1" }}>{/* <span style={{padding:"10px", color:"white", fontSize:"1.3em", borderLeft:"3px solid #ddd"}}>Tele Med</span> */}</div>
						</div>
						<div style={{ gridColumnStart: 2, gridColumnEnd: 5, height: "100%", backgroundColor: "#fff", borderLeft: "1px solid #eee", padding: "20px" }}>
							<div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", width: "100%" }}>
								<div style={{ textAlign: "center", fontSize: "1.5em" }}>
									For consultation
									<br /> please schedule your appoinment.
									<br /> <span style={{ fontSize: "0.8em" }}>our doctors are ready to help</span>
								</div>
								<Button className="button_clr" style={{ width: "60%", margin: "10px", padding: "10px", fontSize: "1em" }} onClick={() => this.setState({ show_side: !this.state.show_side })}>
									Book Appointment
								</Button>
							</div>
							{this.state.show_side && <Appointment class="appointment" side={true} cbd={this.props.cbd} close={(val) => this.setState({ show_side: val })} />}
						</div>
					</div>
				)}
			</div>
		)
	}
}
export default OnlineDoctors

class ListItems extends Component {
	constructor(props) {
		super(props)

		this.state = { doctors: [], loaded: false }
		this.processData = this.processData.bind(this)
	}

	componentDidMount() {
		this.processData(this.props.doctors, (data) => {
			this.setState({
				doctors: data,
				loaded: this.props.doctors.length !== 0 ? true : false,
			})
		})
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevProps.doctors !== this.props.doctors) {
			this.processData(this.props.doctors, (data) => {
				this.setState({
					doctors: data,
					loaded: this.props.doctors.length !== 0 ? true : false,
				})
			})
		}
	}

	processData(doctors, callback) {
		let info = doctors.map((item, i) => {
			const { firstName, lastName } = item
			return [<Item key={i} data={item} cbd={this.props.cbd} />, firstName, lastName]
		})
		if (info) {
			callback(info)
		}
	}

	render() {
		return this.state.loaded ? <CardsList style={this.props.style} doctors={this.state.doctors} /> : <div>loading..</div>
	}
}

class Item extends Component {
	constructor(props) {
		super(props)

		this.state = { [`show_app_${props.mdw_id}`]: false }
	}

	render() {
		const { profile_pic_ext, mdw_id, firstName, lastName, SpecialitiesList, OtherSpecialitiesList, qualification, _id, timings, tags } = this.props.data
		const firstLetter = (d) => (d ? d.split("")[0].toUpperCase() : "")
		const Nm = (a) => a.charAt(0).toUpperCase() + a.slice(1).toLowerCase()
		const chkAvail = (a, b) => (a != "" && a != undefined ? Array.isArray(a) ? a.map((itm) => itm.name + ",") : a : <span style={{ backgroundColor: "#ddd", color: "rgb(111 68 68)", fontSize: "0.8em", padding: "0px 5px" }}>{b}</span>)
		const processTimings = (a) => (timings ? moment(timings[a]["from"], ["HH:mm"]).format("hh:mm A") + " - " + moment(timings[a]["to"], ["HH:mm"]).format("hh:mm A") : false)
		const processTags = (a) =>
			a.split(",").map((itm, i) => (
				<Badge color="primary" key={i}>
					{itm}
				</Badge>
			))
		const checkToday = () => moment().day()
		return (
			<div>
				<div style={{ display: "flex", justifyContent: "space-between" }}>
					<div style={{ display: "flex", justifyContent: "flex-start", width: "40%" }}>
						<div style={{ display: "flex", justifyContent: "space-between", flexDirection: "column", alignItems: "center" }}>
							<div style={{ position: "relative", display: "flex", justifyContent: "center", alignItems: "center", height: "100px", width: "100px", color: "#fff" }}>
								<i style={{ position: "absolute", fontSize: "1.2em", color: "#bd1717", left: "-4px", top: "-4px" }} className="fa fa-check" />
								{profile_pic_ext !== "none" && profile_pic_ext != undefined ? (
									<img style={{ borderRadius: "50%", boxShadow: "0px 0px 2px 2px" }} src={"https://imed-doc-attmts.s3.amazonaws.com/profiles_images/profile_" + mdw_id + "." + profile_pic_ext} width="100px" height="100%" />
								) : (
									<div style={{ fontSize: "3.4em", display: "flex", justifyContent: "center", alignItems: "center", borderRadius: "50%", backgroundColor: "#ddd", width: "100px", height: "100%", boxShadow: "0px 0px 2px 2px" }}>{`${firstLetter(firstName)}${firstLetter(lastName)}`}</div>
								)}
							</div>
							<div style={{ padding: "10px", cursor: "pointer" }} onClick={() => (window.location.href = `${config.domain_url}/doc-profile/${_id}`)}>
								View Profile
							</div>
						</div>
						<div style={{ marginLeft: "10px", padding: "10px", borderLeft: "1px solid #eee" }}>
							<div style={{ margin: "5px" }}>
								<div style={{ fontSize: "1.2em", color: "#7768b3" }}>{`${Nm(firstName)} ${Nm(lastName)}`}</div>
							</div>
							<div style={{ margin: "5px" }}>
								<div style={{ fontSize: "1em", color: "#7768b3" }}>
									<i style={{ fontSize: "1.2em", color: "#bd1717" }} className="fa fa-certificate" /> {chkAvail(SpecialitiesList, "no specializations added")}
								</div>
							</div>
							<div style={{ margin: "5px" }}>
								<div style={{ fontSize: "1em", color: "#7768b3" }}>
									<i style={{ fontSize: "1.2em", color: "#bd1717" }} className="fa fa-certificate" /> {chkAvail(OtherSpecialitiesList, "no other specializations added")}
								</div>
							</div>
							<div style={{ margin: "5px" }}>
								<div style={{ fontSize: "1em", color: "#7768b3" }}>
									<i className="fa fa-stethoscope" /> {chkAvail(qualification, "no qualification added")}
								</div>
							</div>
						</div>
					</div>
					<div style={{ display: "flex", justifyContent: "flex-end", flexDirection: "column", flex: 1, margin: "10px", padding: "10px", textAlign: "right", borderRight: "1px solid #eee" }}>
						<div className="tagsList" style={{ flexWrap: "wrap" }}>
							{tags && processTags(tags)}
						</div>
					</div>
					<div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", flexDirection: "column", width: "20%" }}>
						<div style={{ flex: 1, fontSize: "0.9em", textAlign: "right", color: "#4e4e4e", alignSelf: "flex-end" }}>
							{timings && <div>Timings</div>}
							{timings && (
								<>
									<div style={{ color: "white", backgroundColor: "#353535", padding: "1px 3px", margin: "2px 0px 0px 0px", fontWeight: "bold", fontSize: "1.2em" }}>{`${processTimings("weekdays")}`}</div>
									<div>on weekdays</div>
								</>
							)}
							{timings && (
								<>
									<div style={{ color: "white", backgroundColor: "#353535", padding: "1px 3px", margin: "2px 0px 0px 0px", fontWeight: "bold", fontSize: "1.2em" }}>{`${processTimings("weekends")}`}</div>
									<div>on weekends</div>{" "}
								</>
							)}
						</div>
						{timings ? <div style={{ color: "#4e4e4e" }}>{() => (checkToday() !== 0 && checkToday() !== 1 ? moment().isSameOrAfter(moment(timings.weekdays.from, "hh:mm")) : "")}</div> : <div style={{ color: "#4e4e4e" }}>Availability on confirmation</div>}

						<div style={{ width: "100%" }}>
						{this.props.data && this.props.data.doctor_fee ?
							<Button className="button_clr" style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", width: "100%" }} onClick={() => this.setState({ [`show_app_${mdw_id}`]: !this.state[`show_app_${mdw_id}`] })}>
								{timings ? (
									<div>
										<b>Get Appointment</b>
									</div>
								) : (
									<div>
										<b>Book Appointment</b>
									</div>
								)}
								<div style={{ fontSize: "1.2em", color: "rgb(196 208 13)" }}>{this.props.data && this.props.data.doctor_fee ? "Rs." + this.props.data.doctor_fee : "No Booking Fee"}</div>
							</Button>
							:<Button className="button_clr" style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", width: "100%" }} onClick={() => this.setState({ [`show_app_${mdw_id}`]: !this.state[`show_app_${mdw_id}`] })} disabled>
							{timings ? (
								<div>
									<b>Get Appointment</b>
								</div>
							) : (
								<div>
									<b>Book Appointment</b>
								</div>
							)}
							<div style={{ fontSize: "1.2em", color: "rgb(196 208 13)" }}>{this.props.data && this.props.data.doctor_fee ? "Rs." + this.props.data.doctor_fee : "No Booking Fee"}</div>
						</Button>
						}	
						</div>
					</div>
				</div>
				{this.state[`show_app_${mdw_id}`] && <Appointment side={false} class="appointment" mdw_id={mdw_id} doctor_id={_id} doctorName={`${firstName} ${lastName}`} sai={"45"} appointmentFee={this.props.data.doctor_fee ? this.props.data.doctor_fee : "0"} cbd={this.props.cbd} close={(val) => this.setState({ [`show_app_${mdw_id}`]: val })} />}
			</div>
		)
	}
}

class Appointment extends Component {
	constructor(props) {
		super(props)
		this.now = moment()
		this.appointmentData = { doctor_id: props.doctor_id, doctor_name: props.doctorName, appointmentFee: props.appointmentFee }
		this.state = {
			fields: {},
			errors: {}
		}
		this.handleChange = this.handleChange.bind(this)
		this.handleSubmit = this.handleSubmit.bind(this)
		this.clear = this.clear.bind(this)
		this.getEmailwithRegid = this.getEmailwithRegid.bind(this)
		
	}

	handleChange(field, e) {
		console.log(e)
		let fields = this.state.fields;
         	fields[field] = e.target.value;        
            this.setState({fields});
		this.setState({
			[e.target.name]: e.target.value,
		})
		this.appointmentData[e.target.name] = e.target.value
	}
	
	clear() {
		this.setState({  
			fields: {
			firstName: "",
			lastName: "",
			email: "",
			phone: "",
			date: "",
			reg_id: "",
			check_up: "",
			},
		})
		this.appointmentData = { doctor_id: this.props.doctor_id, doctor_name: this.props.doctorName, appointmentFee: this.props.appointmentFee }
	}

	getEmailwithRegid(email) {
		var emailData = {}
		emailData.email = email
		apiHandler.fetch(
			'post',
			'/hospital/getEmailwithRegid',
			emailData,
			{},
			function (response) {
				if (response.data) {
					this.newData = response.data
				console.log(this.newData)
				console.log(this.newData.res.reg_id)
				this.setState({
					reg_id: this.newData.res.reg_id})
				}
			}.bind(this)	
		)
	}
	handleFormValidation(){
		let fields = this.state.fields;
		let errors = {};
		let formIsValid = true;
		
		if(!fields["firstName"]){
		   formIsValid = false;
		   errors["firstName"] = "Please Enter The First Name";
		} else if(typeof fields["firstName"] !== "undefined"){
		   if(!fields["firstName"].match(/^[a-zA-Z\. ]+$/)){
			  formIsValid = false;
			  errors["firstName"] = "Invalid First Name";
		   } 
		}
		   if(!fields["lastName"]){
			formIsValid = false;
			errors["lastName"] = "Please Enter The Last Name";
		 } else if(typeof fields["lastName"] !== "undefined"){
			if(!fields["lastName"].match(/^[a-zA-Z\. ]+$/)){
			formIsValid = false;
			errors["lastName"] = "Invalid Last Name";
			}       
		}
		if(!fields["email"]){
			formIsValid = false;
			errors["email"] = "Please Enter The Email";
		 } else if(typeof fields["email"] !== "undefined"){
			if(!fields["email"].match(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/)){
			formIsValid = false;
			errors["email"] = "Invalid Email";
			}       
		}
		if(!fields["phone"]){
			formIsValid = false;
			errors["phone"] = "Please Enter The Mobile Number";
		 } else if(typeof fields["phone"] !== "undefined"){
			if(!fields["phone"].match(/^\d{10}$/)){
			formIsValid = false;
			errors["phone"] = "Mobile Number Should Be of Ten Digits";
			}       
		}
	   this.setState({errors: errors});
	   return formIsValid;
   }

	handleSubmit() {
		if(this.handleFormValidation()=== true){
			this.props.cbd(this.appointmentData)		
			this.props.close(true)	
			this.clear()
		}	
	}

	render() {
		console.log(this.appointmentData)
		if (this.props.side) {
			return (
				<div className={this.props.class}>
					<Row>
						<Col md="12" className="mb-3">
							<InputGroup>
								<InputGroupAddon addonType="prepend">
									<i className="fa fa-asterisk text-danger" />
									<i className="fa fa-user-circle" />
								</InputGroupAddon>
								<Input type="text" className="firstName" onChange={this.handleChange.bind(this, "firstName")} value={this.state.fields["firstName"]} name="firstName" id="firstName" placeholder="Patient First Name" />
							</InputGroup>
							<span className="colorvalidation">{this.state.errors["firstName"]}
								{" "}
							</span>
						</Col>
						<Col md="12" className="mb-3">
							<InputGroup>
								<InputGroupAddon addonType="prepend">
									<i className="fa fa-asterisk text-danger" />
									<i className="fa fa-user-circle" />
								</InputGroupAddon>
								<Input type="text" className="lastname" onChange={this.handleChange.bind(this, "lastName")} value={this.state.fields["lastName"]} name="lastName" placeholder="Patient Last Name" />
							</InputGroup>
							<span className="colorvalidation">{this.state.errors["lastName"]}
								{" "}
							</span>
						</Col>
						<Col md="12" className="mb-3">
							<InputGroup>
								<InputGroupAddon addonType="prepend">
								<i className="fa fa-asterisk text-danger" />
									<i className="fa fa-envelope" />
								</InputGroupAddon>
								<Input type="text" className="email" value={this.state.fields["email"]} onChange={this.handleChange.bind(this, "email")} onBlur={(e)=>this.getEmailwithRegid(e.target.value)} name="email" id="email" placeholder="Email Id" />
							</InputGroup>
							<span className="colorvalidation">{this.state.errors["email"]}
								{" "}
							</span>
						</Col>
						<Col md="12" className="mb-3">
							<InputGroup>
								<InputGroupAddon addonType="prepend">
									<i className="fa fa-asterisk text-danger" />
									<i className="fa fa-mobile-phone" />
								</InputGroupAddon>
								<Input type="text" className="phone" value={this.state.fields["phone"]} onChange={this.handleChange.bind(this, "phone")} name="phone" placeholder="Mobile Number" />
							</InputGroup>
							<span className="colorvalidation">{this.state.errors["phone"]}
								{" "}
							</span>
						</Col>
						<Col md="12" className="mb-3">
							<InputGroup>
								<InputGroupAddon addonType="prepend">
									<i className="fa fa-vcard " />
								</InputGroupAddon>
								<Input type="text" className="reg_id" value={this.state.reg_id} onChange={this.handleChange} name="reg_id" placeholder="Patient-ID (if exists)" disabled/>
							</InputGroup>
							<span id="reg_idErr" className="colorvalidation">
								{" "}
							</span>
						</Col>
						<Col md="12" className="mb-3">
							{/* <IM_RadioGroup className="radiogroup" id="date" value={this.state.fields["date"]} name="date" onChange={this.handleChange.bind(this, "date")} items={["Today", "Tomorrow", "NextWeek"]} type="array" status={true} /> */}
							<Input type="date" className="date"  min={this.now.format('YYYY-MM-DD')} value={this.state.fields["date"]} onChange={this.handleChange.bind(this, "date")} name="date" placeholder="Select Date" />
						</Col>

						<Col md="12" className="mb-3">
							<Input type="textarea" className="check_up" value={this.state.fields["check_up"]} onChange={this.handleChange.bind(this, "check_up")} name="check_up" placeholder="Notes" />
						</Col>
						<div className="buttonsGroup" style={{ display: "flex", justifyContent: "flex-end", flex: 1 }}>
							<Button className="button_clear" style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }} onClick={this.clear}>
								<div>
									<b>Clear</b>
								</div>
							</Button>
							<Button className="button_clr" style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }} onClick={this.handleSubmit}>
								<div>
									<b>Submit</b>
								</div>
							</Button>
						</div>
					</Row>
				</div>
			)
		} else {
			return (
				<div className={this.props.class}>
					<Row>
						<Col md="6" className="mb-3">
							<InputGroup>
								<InputGroupAddon addonType="prepend">
									<i className="fa fa-asterisk text-danger" />
									<i className="fa fa-user-circle" />
								</InputGroupAddon>
								<Input type="text" className="firstName" value={this.state.firstName} onChange={this.handleChange.bind(this, "firstName")} value={this.state.fields["firstName"]} name="firstName" id="firstName" placeholder="Patient First Name" />
							</InputGroup>
							<span className="colorvalidation">{this.state.errors["firstName"]}
								{" "}
							</span>
						</Col>
						<Col md="6" className="mb-3">
							<InputGroup>
								<InputGroupAddon addonType="prepend">
									<i className="fa fa-asterisk text-danger" />
									<i className="fa fa-user-circle" />
								</InputGroupAddon>
								<Input type="text" className="lastname" onChange={this.handleChange.bind(this, "lastName")} value={this.state.fields["lastName"]} name="lastName" placeholder="Patient Last Name" />
							</InputGroup>
							<span className="colorvalidation">{this.state.errors["lastName"]}
								{" "}
							</span>
						</Col>
						<Col md="6" className="mb-3">
							<InputGroup>
								<InputGroupAddon addonType="prepend">
								<i className="fa fa-asterisk text-danger" />
									<i className="fa fa-envelope" />
								</InputGroupAddon>
								<Input type="text" className="email" value={this.state.fields["email"]} onChange={this.handleChange.bind(this, "email")} onBlur={(e)=>this.getEmailwithRegid(e.target.value)} name="email" id="email" placeholder="Email Id" />
							</InputGroup>
							<span className="colorvalidation">{this.state.errors["email"]}
								{" "}
							</span>
						</Col>
						<Col md="6" className="mb-3">
							<InputGroup>
								<InputGroupAddon addonType="prepend">
									<i className="fa fa-asterisk text-danger" />
									<i className="fa fa-mobile-phone" />
								</InputGroupAddon>
								<Input type="text" className="phone" value={this.state.fields["phone"]} onChange={this.handleChange.bind(this, "phone")} name="phone" placeholder="Mobile Number" />
							</InputGroup>
							<span className="colorvalidation">{this.state.errors["phone"]}
								{" "}
							</span>
						</Col>
						<Col md="6" className="mb-3">
							<InputGroup>
								<InputGroupAddon addonType="prepend">
									<i className="fa fa-vcard " />
								</InputGroupAddon>
								<Input type="text" className="reg_id" value={this.state.reg_id} name="reg_id" placeholder="Patient-ID (if exists)" disabled/>
							</InputGroup>
							<span id="reg_idErr" className="colorvalidation">
								{" "}
							</span>
						</Col>
						<Col md="6" className="mb-3">
							{/* <IM_RadioGroup className="radiogroup" id="date" value={this.state.fields["date"]} name="date" onChange={this.handleChange.bind(this, "date")} items={["Today", "Tomorrow", "NextWeek"]} type="array" status={true} /> */}
							<Input type="date" className="date"  min={this.now.format('YYYY-MM-DD')} value={this.state.fields["date"]} onChange={this.handleChange.bind(this, "date")} name="date" placeholder="Select Date" />
						</Col>

						<Col md="12" className="mb-3">
							<Input type="textarea" className="check_up" value={this.state.fields["check_up"]} onChange={this.handleChange.bind(this, "check_up")} name="check_up" placeholder="Notes" />
						</Col>
						<div className="buttonsGroup" style={{ display: "flex", justifyContent: "flex-end", flex: 1 }}>
							<Button className="button_clear" style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }} onClick={this.clear}>
								<div>
									<b>Clear</b>
								</div>
							</Button>
							<Button className="button_clr" style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }} onClick={this.handleSubmit}>
								<div>
									<b>Submit</b>
								</div>
							</Button>
						</div>
					</Row>
				</div>
			)
		}
	}
}
