"use strict"
import moment from "moment"
import constants from "./constants.js"
var $ = require("jquery"),
	_ = require("lodash")
var emailPattern = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/
var numberPattern = /^\d{10}$/
var client_num = /^(\+91[\-\s]?)?[0]?(91)?[789]\d{9}$/
var onlynumbers = /^\d+$/
var decimalnumbers = /^\d*(\.\d+)?$/
var onlytext = /^[a-zA-Z]+$/
var string = /^[\S\S\s]+$/
var onlyname = /^[a-zA-Z\. ]+$/
var postalCode = /^[1-9]{1}[0-9]{2}\s{0,1}[0-9]{3}$/
const summary = /^[a-zA-Z0-9.,-_ ]+$/
const medicine_name = /^[_a-zA-Z0-9- ]+$/
const aadharnumber = /^\d{4}\s\d{4}\s\d{4}$/
const address = /^[a-zA-Z0-9,.!? ]*$/
const height = /[0-9]|\./
const blood_group = /^(A|B|AB|O)[+-]$/
const sgst_cgst = /^[0-9]*\.?[0-9]*$/
const num_with_space = /^[_0-9- ]+$/
const url = /^(?:(?:https?|ftp):\/\/)(?:\S+(?::\S*)?@)?(?:(?!10(?:\.\d{1,3}){3})(?!127(?:\.​\d{1,3}){3})(?!169\.254(?:\.\d{1,3}){2})(?!192\.168(?:\.\d{1,3}){2})(?!172\.(?:1[​6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1​,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00​a1-\uffff0-9]+-?)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]+-?)*[a-z\u​00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/[^\s]*)?$/
function formValidations() {
	this.spotValidate = function (value, type) {
		if (type ==="number") {
			let r = onlynumbers.test(value)
			return r
		} else if (type ==="string") {
			let r = string.test(value)
			return r
		} else if (type ==="decimal") {
			let r = decimalnumbers.test(value)
			return r
		} else if (type ==="money") {
			let r = decimalnumbers.test(value)
			return r
		} else if (type ==="text") {
			let r = onlytext.test(value)
			return r
		} else if (type ==="email") {
			let r = emailPattern.test(value)
			return r
		} else if (type ==="phone") {
			let r = onlynumbers.test(value)
			return r
		}else if (type ==="tax") {
			let r = sgst_cgst.test(value)
			return r
		}else if (type ==="all") {
			if(value == ""){
				return false
			}
			else{
				return true
			}
		}
	}

	this.validateParamsForm = function (dynamicParams) {
		var validForm = true
		if (dynamicParams.paramName ==="") {
			$("#paramNameErr").text("Please enter Name")
			validForm = false
		} else if (constants.REGEX_ALPH_NUM_HYPHEN.test(dynamicParams.paramName) ===false) {
			$("#paramNameErr").text("Allows only alphanumeric(a-zA-Z0-9) and Hyphen(-)")
			validForm = false
		} else {
			$("#paramNameErr").text("")
		}
		if (dynamicParams.paramType ==="") {
			$("#paramTypeErr").text("Please select Type")
			validForm = false
		} else {
			$("#paramTypeErr").text("")
		}
		if (dynamicParams.entityName ==="") {
			$("#entityNameErr").text("Please enter Entity Name")
			validForm = false
		} else {
			$("#entityNameErr").text("")
		}
		if (!validForm) return false

		return true
	}
	this.validateTrainingRegexForm = function (trainingRegex) {
		var validForm = true
		if (trainingRegex.paramName ==="") {
			$("#paramNameErr").text("Please enter Name")
			validForm = false
		} else if (constants.REGEX_ALPH_NUM_HYPHEN.test(trainingRegex.paramName) ===false) {
			$("#paramNameErr").text("Allows only alphanumeric(a-zA-Z0-9) and Hyphen(-)")
			validForm = false
		} else {
			$("#paramNameErr").text("")
		}
		if (trainingRegex.paramType ==="") {
			$("#paramTypeErr").text("Please select Type")
			validForm = false
		} else {
			$("#paramTypeErr").text("")
		}
		if (trainingRegex.entityName ==="") {
			$("#entityNameErr").text("Please enter Entity Name")
			validForm = false
		} else {
			$("#entityNameErr").text("")
		}
		if (!validForm) return false

		return true
	}
	this.validateBotForm = function (chat) {
		var validForm = true
		if (chat.name ==="") {
			$("#nameErr").text("Please Enter Facility Name")
			validForm = false
		} else {
			$("#nameErr").text("")
		}
		if (chat.description ==="") {
			$("#descriptionErr").text("Please enter Description")
			validForm = false
		} else {
			$("#descriptionErr").text("")
		}
		if (chat.bot_type ==="") {
			$("#bot_typeErr").text("Please Select bot Type")
			validForm = false
		} else {
			$("#bot_typeErr").text("")
		}
		if (!validForm) return false

		return true
	}

	this.validateClientForm = function (clientInfo) {
		var validForm = true
		if (clientInfo.client_type == "") {
			$("#client_typeErr").text("Please Select Facility Type")
			validForm = false
		} else {
			$("#client_typeErr").text("")
		}
		if (clientInfo.client_name ==="") {
			$("#client_nameErr").text("Please Enter The Facility Name")
			validForm = false
		} else {
			$("#client_nameErr").text("")
		}
		if (clientInfo.client_desc ==="") {
			$("#client_descErr").text("Please Enter Facility Description")
			validForm = false
		} else {
			$("#client_descErr").text("")
		}
		if (clientInfo.client_reg_prefix ==="") {
			$("#client_reg_prefixErr").text("Please Enter Registration Prefix")
			validForm = false
		} else {
			$("#client_reg_prefixErr").text("")
		}
		if (clientInfo.client_reg_start ==="") {
			$("#client_reg_startErr").text("Please Enter Registration Starting Number")
			validForm = false
		} else {
			$("#client_reg_startErr").text("")
		}
		// if (clientInfo.client_reg_start ==="") {
		// 	$("#client_reg_startErr").text("Please Enter Registration Starting No")
		// 	validForm = false
		// } else if (!onlynumbers.test(clientInfo.client_reg_start)) {
		// 	$("#client_reg_startErr").text("Invalid Registration No")
		// 	validForm = false
		// } else {
		// 	$("#client_reg_startErr").text("")
		// }
		if (clientInfo.client_phone ==="") {
		
			$("#client_phoneErr").text("Please Enter Mobile Number")
			validForm = false
		} else {
			$("#client_phoneErr").text("")
		}
		// if (clientInfo.client_subdomain ==="") {	
		// } else if (!onlyname.test(clientInfo.client_subdomain)) {
		// 	$("#client_subdomainErr").text("Invalid subDomain")
		// 	validForm = false
		// } else {
		// 	$("#client_subdomainErr").text("")
		// }
		// if (clientInfo.client_logo_url ==="") {
		// } else if (!url.test(clientInfo.client_logo_url)) {
		// 	$("#client_logourlErr").text("Invalid URL")
		// 	validForm = false
		// } else {
		// 	$("#client_logourlErr").text("")
		// }
		if (clientInfo.firstName ==="") {
			$("#firstNameErr").text("Please Enter Given Name")
			validForm = false
		} else if (!onlyname.test(clientInfo.firstName)) {
			$("#firstNameErr").text("Invalid text")
			validForm = false
		} else {
			$("#firstNameErr").text("")
		}
		if (clientInfo.lastName ==="") {
			$("#lastNameErr").text("Please Enter Surname")
			validForm = false
		} else if (!onlyname.test(clientInfo.lastName)) {
			$("#lastNameErr").text("Invalid text")
			validForm = false
		} else {
			$("#lastNameErr").text("")
		}
		if (clientInfo.email ==="") {
			$("#emailErr").text("Please Enter Valid Email")
			validForm = false
		} else if (!emailPattern.test(clientInfo.email)) {
			$("#emailErr").text("Invalid Email")
			validForm = false
		} else {
			$("#emailErr").text("")
		}
		if (clientInfo.mobile_number ==="") {
		
			$("#mobile_numberErr").text("Please Enter Mobile Number")
			validForm = false
		} else {
			$("#mobile_numberErr").text("")
		}
		
		if (!validForm) return false
		return true
	}
	this.validateEditClientForm = function (clientInfo) {
		var validForm = true
		if (clientInfo.client_type ==="") {
			$("#client_typeErr").text("Please select facility Type")
			validForm = false
		} else {
			$("#client_typeErr").text("")
		}
		if (clientInfo.client_name ==="") {
			$("#client_nameErr").text("Please Enter The Facility Name")
			validForm = false
		} else {
			$("#client_nameErr").text("")
		}
		if (clientInfo.client_desc ==="") {
			$("#client_descErr").text("Please Enter Facility Description")
			validForm = false
		} else {
			$("#client_descErr").text("")
		}
		if (clientInfo.client_reg_prefix ==="") {
			$("#client_reg_prefixErr").text("Please Enter Registration Prefix")
			validForm = false
		
		} else {
			$("#client_reg_prefixErr").text("")
		}
		if (clientInfo.client_reg_start ==="") {
			$("#client_reg_startErr").text("Please Enter Registration Starting Number")
			validForm = false
		
		} else {
			$("#client_reg_startErr").text("")
		}
		// if (clientInfo.client_reg_start ==="") {
		// 	$("#client_reg_startErr").text("Please Enter Registration Starting No")
		// 	validForm = false
		// } else if (!onlynumbers.test(clientInfo.client_reg_start)) {
		// 	$("#client_reg_startErr").text("Invalid Registration No")
		// 	validForm = false
		// } else {
		// 	$("#client_reg_startErr").text("")
		// }
		if (clientInfo.client_phone ==="") {
			$("#client_phoneErr").text("Please Enter Mobile Number")
			validForm = false
		} else {
			$("#client_phoneErr").text("")
		}
		// if (clientInfo.client_subdomain ==="") {	
		// } else if (!onlyname.test(clientInfo.client_subdomain)) {
		// 	$("#client_subdomainErr").text("Invalid subDomain")
		// 	validForm = false
		// } else {
		// 	$("#client_subdomainErr").text("")
		// }
		// if (clientInfo.client_logo_url ==="") {
		// } else if (!url.test(clientInfo.client_logo_url)) {
		// 	$("#client_logourlErr").text("Invalid URL")
		// 	validForm = false
		// } else {
		// 	$("#client_logourlErr").text("")
		// }
		if (!validForm) return false
		return true
	}

	this.validateLoginForm = function (login) {
		var validForm = true
		if (login.email ==="") {
			$("#emailErr").text("Please Enter Valid Email")
			validForm = false
		} else if (!emailPattern.test(login.email)) {
			$("#emailErr").text("Invalid Email")
			validForm = false
		} else {
			$("#emailErr").text("")
		}
		if (login.password ==="") {
			$("#passwordErr").text("Please Enter Password")
			validForm = false
		} else {
			$("#password").text("")
		}
		if (!validForm) return false

		return true
	}

	this.validateSSOLoginForm = function (login) {
		var validForm = true
		if (login.email ==="") {
			$("#emailErr").text("Please Enter Valid Email")
			validForm = false
		} else if (!emailPattern.test(login.email)) {
			$("#emailErr").text("Invalid Email")
			validForm = false
		} else {
			$("#emailErr").text("")
		}
		if (!validForm) return false

		return true
	}

	this.validateForgotPassword = function (forgotPwd) {
		var validForm = true
		if (forgotPwd.email ==="") {
			$("#forgotemailErr").text("Please Enter Valid Email")
			validForm = false
		} else if (!emailPattern.test(forgotPwd.email)) {
			$("#forgotemailErr").text("Invalid Email")
			validForm = false
		} else {
			$("#emailErr").text("")
		}
		if (!validForm) return false

		return true
	}
	this.validateChangePassword = function (changePassword) {
		var validForm = true
		if (changePassword.oldPassword ==="") {
			$("#oldPasswordErr").text("Please enter old password")
			validForm = false
		} else {
			$("#oldPasswordErr").text("")
		}
		if (changePassword.password ==="") {
			$("#passwordErr").text("Please enter new password")
			validForm = false
		} else if (changePassword.password ===changePassword.oldPassword) {
			$("#passwordErr").text("New password should not be same as old password")
			validForm = false
		} else {
			$("#passwordErr").text("")
		}
		if (changePassword.confirmPassword ==="") {
			$("#confirmPasswordErr").text("Please enter confirm password")
			validForm = false
		} else if (changePassword.confirmPassword !== changePassword.password) {
			$("#confirmPasswordErr").text("New password and confirm password should be same")
			validForm = false
		} else {
			$("#confirmPasswordErr").text("")
		}

		if (!validForm) return false
		return true
	}
	this.validatemadications = function (state) {
		var validForm = true
		// if (state.medicine ==="") {
		// 	$("#medicineErr").text("Enter The Medicine Name")
		// 	validForm = false
		// } else if (!onlyname.test(state.medicine)) {
		// 	$("#medicineErr").text("Invalid Medicine Name")
		// 	validForm = false
		// } else {
		// 	$("#medicineErr").text("")
		// }

		if (state.medicine ==="") {
			$("#medicineErr").text("Enter The Medicine Name")
			validForm = false
		} else if (!medicine_name.test(state.medicine)) {
			$("#medicineErr").text("Invalid Medicine Name")
		} else {
			$("#medicineErr").text("")
		}

		if (state.days ==="") {
			$("#daysErr").text("Enter The Days")
			validForm = false
		} else if (!onlynumbers.test(state.days)) {
			$("#daysErr").text("Invalid Days")
			validForm = false
		} else {
			$("#daysErr").text("")
		}
		if (state.summary ==="") {
			$("#summaryErr").text("Enter The Summary")
			validForm = false
		} else if (!summary.test(state.summary)) {
			$("#summaryErr").text("Invalid Summary")
			validForm = false
		} else {
			$("#summaryErr").text("")
		}

		if (!validForm) return false
		return true
	}

	this.validateManageSectionType = function ( sectiontype ) {
		var validForm = true

		if (sectiontype.category_type ==="" || sectiontype.category_type ===undefined) {
			$("#categorytypeErr").text("Please Select Section Type")
			validForm = false
		} else {
			$("#categorytypeErr").text("")
		}
		if (sectiontype.section_name ==="" || sectiontype.section_name ===undefined) {
			$("#sectionnameErr").text("Please Enter Name")
			validForm = false
		} else {
			$("#sectionnameErr").text("")
		}
		if (sectiontype.section_type ==="" || sectiontype.section_type ===undefined) {
			$("#sectiontypeErr").text("Please Select Type")
			validForm = false
		} else {
			$("#sectiontypeErr").text("")
		}
		if (sectiontype.default_column === "" || sectiontype.default_column === undefined) {
			$("#sectioncolumnErr").text("Please Select Columns")
			validForm = false
		} else {
			$("#sectioncolumnErr").text("")
		}


		if(!validForm) return false
		return true
	}

	this.validateManageSectionTemplates = function (Sectiontemplate) {
		var validForm = true

		if (Sectiontemplate.category_type ==="" || Sectiontemplate.category_type ===undefined) {
			$("#categorytypeErr").text("Please Select Section Type")
			validForm = false
		} else {
			$("#categorytypeErr").text("")
		}
		if (Sectiontemplate.section_template_name ==="" || Sectiontemplate.section_template_name ===undefined) {
			$("#sectiontemplatenameErr").text("Please Enter Title")
			validForm = false
		} else {
			$("#sectiontemplatenameErr").text("")
		}
		// if (Sectiontemplate.tests ==="" || Sectiontemplate.tests ===undefined) {
		// 	$("#testsErr").text("Please Select Fields")
		// 	validForm = false
		// } else {
		// 	$("#testsErr").text("")
		// }

		if (Sectiontemplate.selectedTests ==="" || Sectiontemplate.selectedTests ===undefined) {
			$("#testsErr").text("Please Select Fields")
			validForm = false
		} else {
			$("#testsErr").text("")
		}
		
		
		if(!validForm) return false
		return true

	}


	this.userForm = function (userRegister, client_type) {
		var validForm = true

		if (userRegister.firstName ==="") {
			$("#firstNameErr").text("Please Enter Given Name")
			validForm = false
		} else if (!onlyname.test(userRegister.firstName)) {
			$("#firstNameErr").text("Invalid text")
			validForm = false
		} else {
			$("#firstNameErr").text("")
		}
		if (userRegister.lastName ==="") {
			$("#lastNameErr").text("Please Enter Surname")
			validForm = false
		} else if (!onlyname.test(userRegister.lastName)) {
			$("#lastNameErr").text("Invalid text")
			validForm = false
		} else {
			$("#lastNameErr").text("")
		}
		if (userRegister.email ==="") {
			$("#emailErr").text("Please Enter Valid Email")
			validForm = false
		} else if (!emailPattern.test(userRegister.email)) {
			$("#emailErr").text("Invalid Email")
			validForm = false
		} else {
			$("#emailErr").text("")
		}
		if (userRegister.password ==="") {
			$("#passwordErr").text("Please Enter Password")
			validForm = false
		} else {
			$("#passwordErr").text("")
		}
		// if (client_type !== "lab") {
		if (userRegister.cnf_password ==="") {
			$("#cnf_passwordErr").text("Please Enter Password")
			validForm = false
		} else if (userRegister.cnf_password !== userRegister.password) {
			$("#cnf_passwordErr").text("Password didn't matched")
			validForm = false
		} else {
			$("#cnf_passwordErr").text("")
		}

		if (userRegister.gender ==="") {
			$("#genderErr").text("Please Select Gender")
			$(".gender").focus()
			validForm = false
		} else {
			$("#genderErr").text("")
		}

		if (userRegister.status ==="") {
			$("#statusErr").text("Please select status")
			validForm = false
		} else {
			$("#statusErr").text("")
		}

		if (userRegister.mobile_number ==="") {
			$("#mobile_numberErr").text("Please Enter Mobile Number")
			validForm = false
		} else if (!numberPattern.test(userRegister.mobile_number)) {
			$("#mobile_numberErr").text("Mobile Number Should Be of Ten Digits")
			validForm = false
		} else {
			$("#mobile_numberErr").text("")
		}
		if (userRegister.user_roles.length ===0) {
			$("#accessUserTypeErr").text("Please select access type")
			validForm = false
		} else {
			$("#accessUserTypeErr").text("")
		}
		if (client_type !== "lab") {
			{/* if (userRegister.qualification ==="") {
				// $("#qualificationErr").text("Please Enter The Qualification")
				// validForm = false
			} else if (!summary.test(userRegister.qualification)) {
				$("#qualificationErr").text("Invalid Qualification")
				validForm = false
			} else {
				$("#qualificationErr").text("")
			}
			if (userRegister.title ==="") {
				// $("#titleErr").text("Please Enter The title")
				// validForm = false
			} else if (!onlyname.test(userRegister.title)) {
				$("#titleErr").text("Invalid title")
				validForm = false
			} else {
				$("#titleErr").text("")
			}
			if (userRegister.license_no ==="") {
				// $("#license_noErr").text("Please Enter The License_No")
				// validForm = false
			} else if (!onlynumbers.test(userRegister.license_no)) {
				$("#license_noErr").text("Invalid Registration number")
				validForm = false
			} else {
				$("#license_noErr").text("")
			} */}
		}

		/* if(userRegister.access_user_type!==='' && userRegister.user_role ===''){
         $('#user_roleErr').text('Please Select User Role');
          validForm = false; 
     } else {
         $('#user_roleErr').text('');
     }*/

		if (!validForm) return false

		return true
	}
	this.userEditForm = function (userRegister) {
		var validForm = true

		if (userRegister.firstName ==="") {
			$("#firstNameErr").text("Please Enter Given Name")
			$(".firstName").focus()
			validForm = false
		} else if (!onlyname.test(userRegister.firstName)) {
			$("#firstNameErr").text("Invalid text")
			validForm = false
		} else {
			$("#firstNameErr").text("")
		}
		if (userRegister.lastName ==="") {
			$("#lastNameErr").text("Please Enter Surname")
			$(".lastName").focus()
			validForm = false
		} else if (!onlyname.test(userRegister.lastName)) {
			$("#lastNameErr").text("Invalid text")
			validForm = false
		} else {
			$("#lastNameErr").text("")
		}
		if (userRegister.email ==="") {
			$("#emailErr").text("Please Enter Valid Email")
			validForm = false
		} else if (!emailPattern.test(userRegister.email)) {
			$("#emailErr").text("Invalid Email")
			validForm = false
		} else {
			$("#emailErr").text("")
		}

		if (userRegister.mobile_number ==="") {
			$("#mobile_numberErr").text("Please Enter Mobile Number")
			validForm = false
		} else if (!numberPattern.test(userRegister.mobile_number)) {
			$("#mobile_numberErr").text("Mobile Number Should Be of Ten Digits")
			validForm = false
		} else {
			$("#mobile_numberErr").text("")
		}
		if (userRegister.user_roles.length ===0) {
			$("#accessUserTypeErr").text("Please select access type")
			validForm = false
		} else {
			$("#accessUserTypeErr").text("")
		}
		if (userRegister.gender ==="") {
			$("#genderErr").text("Please Select Gender")
			$(".gender").focus()
			validForm = false
		} else {
			$("#genderErr").text("")
		}
		{/* if (userRegister.qualification ==="") {
			// $("#qualificationErr").text("Please Enter The Qualification")
			// validForm = false
		} else if (!medicine_name.test(userRegister.qualification)) {
			$("#qualificationErr").text("Invalid Qualification")
			validForm = false
		} else {
			$("#qualificationErr").text("")
		}
		if (userRegister.title ==="") {
			// $("#titleErr").text("Please Enter The title")
			// validForm = false
		} else if (!onlyname.test(userRegister.title)) {
			$("#titleErr").text("Invalid title")
			validForm = false
		} else {
			$("#titleErr").text("")
		}
		if (userRegister.license_no ==="") {
			// $("#license_noErr").text("Please Enter The License_No")
			// validForm = false
		} else if (!onlynumbers.test(userRegister.license_no)) {
			$("#license_noErr").text("Invalid Registration number")
			validForm = false
		} else {
			$("#license_noErr").text("")
		} */}
		/* if(userRegister.access_user_type!==='' && userRegister.user_role ===''){
         $('#user_roleErr').text('Please Select User Role');
          validForm = false; 
     } else {
         $('#user_roleErr').text('');
     }*/

		if (!validForm) return false

		return true
	}

	this.validateManageLookupHMS = function (lookup) {
		var validForm = true

		if (lookup.lookup_value ==="" || lookup.lookup_value ===undefined) {
			$("#lookupValueErr").text("Please Enter Lookup Value")
			$(".lookup_value").focus()
			validForm = false
		} else {
			$("#lookupValueErr").text("")
		}
		if (lookup.lookup_name ==="" || lookup.lookup_name ===undefined) {
			$("#lookupErr").text("Please Select Title")
			$(".lookup_value").focus()
			validForm = false
		} else {
			$("#lookupErr").text("")
		}

		if (!validForm) return false

		return true
	}

	this.validateFields = function (field) {
		var validForm = true

		if (field.items ==="" || field.items ===undefined) {
			$("#itemsErr").text("Please enter default value")
			$(".items").focus()
			validForm = false
		} else {
			$("#itemsErr").text("")
		}

		if (field.default_value ==="" || field.default_value ===undefined) {
			$("#default_valueErr").text("Please enter default value")
			$(".default_value").focus()
			validForm = false
			// }	else if (!onlynumbers.test(field.default_value)) {
			// 	$("#default_valueErr").text("Invalid Value")
			// 	validForm = false
		} else {
			$("#default_valueErr").text("")
		}

		if (field.label ==="" || field.label ===undefined) {
			$("#labelErr").text("Please enter label")
			$(".label").focus()
			validForm = false
			// }	else if (!onlytext.test(field.label)) {
			// 		$("#labelErr").text("Invalid Label")
			// 		validForm = false
		} else {
			$("#labelErr").text("")
		}

		if (field.priority ==="" || field.priority ===undefined) {
			$("#priorityErr").text("Please Select Priority")
			$(".priority").focus()
			validForm = false
		} else {
			$("#priorityErr").text("")
		}

		if (field.field_type ==="" || field.field_type ===undefined) {
			$("#field_typeErr").text("Please Select Field Type")
			$(".field_type").focus()
			validForm = false
		} else {
			$("#field_typeErr").text("")
		}

		if (field.section ==="" || field.section ===undefined) {
			$("#sectionErr").text("Please Select Section")
			$(".section").focus()
			validForm = false
		} else {
			$("#sectionErr").text("")
		}

		if (field.form_name ==="" || field.form_name ===undefined) {
			$("#form_nameErr").text("Please Select Form Name")
			$(".form_name").focus()
			validForm = false
		} else {
			$("#form_nameErr").text("")
		}

		if (!validForm) return false

		return true
	}

	this.validatePatientForm = function (userRegister) {
		var validForm = true
		if (userRegister.alternate_person ==="") {
			// $("#alterperErr").text(" ")
			// $(".alterperErr").focus()
			// validForm = false
		} else if (!onlyname.test(userRegister.alternate_person)) {
			$("#alterperErr").text("Invalid Alternate Person")
			validForm = false
		} else {
			$("#alterperErr").text("")
		}
		// if (userRegister.referred ==="") {
		// 	// $("#referredErr").text(" ")
		// 	// $(".referredErr").focus()
		// 	// validForm = false
		// } else if (!onlyname.test(userRegister.referred)) {
		// 	$("#referredErr").text("Invalid Referred")
		// 	validForm = false
		// } else {
		// 	$("#referredErr").text("")
		// }
		if (userRegister.state ==="") {
			// 	$("#stateErr").text("Please Enter State name")
			// 	validForm = false
		} else if (!onlyname.test(userRegister.state)) {
			$("#stateErr").text("Invalid state")
			validForm = false
		} else {
			$("#stateErr").text("")
		}
		if (userRegister.emergency_contact ==="") {
			// $("#emergency_contactErr").text("Please Enter Emergency Contact")
			// $(".emergency_contact").focus()
			//validForm = false
		} else if (!numberPattern.test(userRegister.emergency_contact)) {
			$("#emergency_contactErr").text("Emergency Contact Should Be of Ten Digits")
			validForm = false
		} else {
			$("#emergency_contactErr").text("")
		}

		if (userRegister.doctor_name ==="" || userRegister.doctor_name ===undefined) {
			$("#doctorErr").text("Please Select doctor")
			$(".doctor").focus()
			validForm = false
		} else {
			$("#doctorErr").text("")
		}
		if (userRegister.mobile_number ==="") {
			$("#mobile_numberErr").text("Please Enter Mobile Number")
			$(".mobile_number").focus()
			validForm = false
		} else if (!numberPattern.test(userRegister.mobile_number)) {
			$("#mobile_numberErr").text("Mobile Number Should Be of Ten Digits")
			validForm = false
		} else {
			$("#mobile_numberErr").text("")
		}
		if (userRegister.email) {
			if (!emailPattern.test(userRegister.email)) {
				$("#emailErr").text("Invalid Email")
				validForm = false
			} else {
				$("#emailErr").text("")
			}
		}
		if (userRegister.weight ) {
		// if (userRegister.weight ==="" || parseInt(userRegister.weight) <= 0) {
			// $("#weightErr").text("Please Enter The Weight (Greater Than Zero)")
			// $(".weight").focus()
			// validForm = false
		
		 if (!onlynumbers.test(userRegister.weight)|| parseInt(userRegister.weight) <= 0) {
			$("#weightErr").text("Invalid Weight")
			validForm = false
		} 
		else {
			$("#weightErr").text("")
		}
	}
		if (userRegister.height ) {

		 if (!height.test(userRegister.height)) {
			$("#heightErr").text("Invalid Height")
			validForm = false
		} else {
			$("#heightErr").text("")
		}
	}
		// if (userRegister.blood_group ==="" ) {
		// 	// $("#blood_groupErr").text("Please Enter The Blood Group")
		// 	// validForm = false
		// } else if (!blood_group.test(userRegister.blood_group)) {
		// 	$("#blood_groupErr").text("Invalid Blood Group")
		// 	validForm = false
		// } else {
		// 	$("#blood_groupErr").text("")
		// }

		if (userRegister.qualification ==="") {
			// $("#qualificationErr").text("Please Enter The Qualification")
			// validForm = false
		} else if (!onlyname.test(userRegister.qualification)) {
			$("#qualificationErr").text("Invalid Qualification")
			validForm = false
		} else {
			$("#qualificationErr").text("")
		}
		if (userRegister.gender ==="") {
			$("#genderErr").text("Please Select Gender")
			$(".gender").focus()
			validForm = false
		} else {
			$("#genderErr").text("")
		}
		if (userRegister.age ==="" || parseInt(userRegister.age) <= 0) {
			$("#ageErr").text("Please Enter The AGE (Greater Than Zero)")
			$(".age").focus()
			validForm = false
			// } else if(parseInt(userRegister.age) <= 0 ){
			// 	$("#ageErr").text("Please Enter The AGE (Greater Than Zero)")
			// 	validForm = false
		} else if (!onlynumbers.test(userRegister.age)) {
			$("#ageErr").text("Invalid AGE")
			validForm = false
		} else {
			$("#ageErr").text("")
		}

		if (userRegister.aadhar) {
			if (!aadharnumber.test(userRegister.aadhar)) {
				$("#aadharErr").text("Aadhar Number Should Be 12 Digits")
				validForm = false
			} else {
				$("#aadharErr").text("")
			}
		}

		// if (userRegister.dob ==="") {
		// 	$("#dobErr").text("Please Select DOB")
		// 	$(".dob").focus()
		// 	validForm = false
		// }
		// // else if(!onlynumbers.test(userRegister.dob)) {
		// // 	$("#dobErr").text("Invalid DOB")
		// // 	validForm = false
		// // }
		// else {
		// 	$("#dobErr").text("")
		// }
		if (userRegister.reg_id ==="" || userRegister.reg_id ===undefined) {
			$("#regIDErr").text("Please Enter Registration ID")
			$(".reg_id").focus()
			validForm = false
		} else {
			$("#regIDErr").text("")
		}

		// if (userRegister.lastName ==="") {
		// 	$("#lastNameErr").text("Please Enter Surname")
		// 	$(".lastName").focus()
		// 	validForm = false
		// } else if (!onlyname.test(userRegister.lastName)) {
		// 	$("#lastNameErr").text("Invalid text")
		// 	validForm = false
		// } else {
		// 	$("#lastNameErr").text("")
		// }

		if (userRegister.firstName ==="") {
			$("#firstNameErr").text("Please Enter Given Name")
			$(".firstName").focus()
			validForm = false
		} else if (!onlyname.test(userRegister.firstName)) {
			$("#firstNameErr").text("Invalid text")
			validForm = false
		} else {
			$("#firstNameErr").text("")
		}

		if (userRegister.occupation ==="") {
			// $("#occupationErr").text("Please Enter Surname")
			// $(".lastName").focus()
			// validForm = false
		} else if (!onlyname.test(userRegister.occupation)) {
			$("#occupationErr").text("Invalid occupation")
			validForm = false
		} else {
			$("#occupationErr").text("")
		}

		if (userRegister.existing_medical_history.length > 0) {
			var other_status = userRegister.existing_medical_history[0].options.includes("Others")
			if (other_status) {
				if (userRegister.others ==="") {
					// $("#othersErr").text("Please Enter Surname")
					// $(".lastName").focus()
					// validForm = false
				// } else if (!onlyname.test(userRegister.others)) {
				// 	$("#othersErr").text("Invalid text")
				// 	validForm = false
				} else {
					$("#othersErr").text("")
				}
			}
		}
		console.log(!validForm)
		if (!validForm) return false
		return true
	}

	this.patientValidateVitalForm = function (register) {
		var validForm = true
		if (register.visitPurpose ==="" || register.visitPurpose ===undefined) {
			$("#visitErr").text("Please Enter Visit Purpose")
			$(".visitPurpose").focus()
			validForm = false
		} else if (!onlyname.test(register.visitPurpose)) {
			$("#visitErr").text("Invalid Visit Purpose")
			validForm = false
		} else {
			$("#visitErr").text("")
		}
		if (register.temprature ==="" || register.temprature ===undefined || register.temprature <= 0) {
			//$("#tempErr").text("Please Enter The Temprature Value(Greater Than Zero)")
			//$(".tempErr").focus()
			//validForm = false
		} else if (!onlynumbers.test(register.temprature)) {
			$("#tempErr").text("Please Enter the Temprature Value")
			validForm = false
		} else {
			$("#tempErr").text("")
		}
		if (register.bp) {
			var bpCheck = register.bp.includes("/")
		}

		if (register.bp ==="" || register.bp ===undefined) {
			// $("#bpErr").text("Please Enter The BP (Ex: 80/120)")
			// $(".bpErr").focus()
			// validForm = false
		} else if (bpCheck ===false) {
			$("#bpErr").text("Invalid Value (Ex: 80/120)")
			validForm = false
		} else {
			$("#bpErr").text("")
		}
		if (register.sugar ==="" || register.sugar ===undefined || register.sugar <= 0) {
			//$("#sugarErr").text("Please Enter The Sugar Value(Greater Than Zero)")
			//$(".sugarErr").focus()
			//validForm = false
		} else if (!onlynumbers.test(register.sugar)) {
			$("#sugarErr").text("Please Enter The Sugar Value")
			validForm = false
		} else {
			$("#sugarErr").text("")
		}

		if (!validForm) return false

		return true
	}

	//  vital start //

	this.validateVitalForm = function (vital) {
		var validForm = true

		if (vital.firstName ==="") {
			$("#firstNameErr").text("Please Enter Given Name")
			$(".firstName").focus()
			validForm = false
		} else if (!onlyname.test(vital.firstName)) {
			$("#firstNameErr").text("Invalid text")
			validForm = false
		} else {
			$("#firstNameErr").text("")
		}

		if (vital.lastName ==="") {
			$("#lastNameErr").text("Please Enter lastName")
			$(".lastName").focus()
			validForm = false
		} else if (!onlyname.test(vital.lastName)) {
			$("#lastNameErr").text("Invalid text")
			validForm = false
		} else {
			$("#lastNameErr").text("")
		}

		// if (vital.age ==="") {
		// 	$("#ageErr").text("Please Enter Weight")
		// 	$(".age").focus()
		// 	validForm = false
		// } else if (!onlynumbers.test(vital.age)) {
		// 	$("#ageErr").text("Please Enter weight")
		// 	validForm = false
		// } else {
		// 	$("#ageErr").text("")
		// }

		if (vital.dob ==="") {
			$("#dobErr").text("Please Select DOB")
			$(".dob").focus()
			validForm = false
		} else {
			$("#dobErr").text("")
		}

		if (vital.weight ==="") {
			$("#weightErr").text("Please Enter Weight")
			$(".weight").focus()
			validForm = false
		} else if (!onlynumbers.test(vital.weight)) {
			$("#weightErr").text("Please Enter weight")
			validForm = false
		} else {
			$("#weightErr").text("")
		}

		// if (userRegister.doctor ==="" || userRegister.doctor ===undefined) {
		// 	$("#doctorErr").text("Please Select doctor")
		// 	$(".doctor").focus()
		// 	validForm = false
		// } else {
		// 	$("#doctorErr").text("")
		// }
		if (vital.mobile_number ==="") {
			$("#mobile_numberErr").text("Please Enter Mobile Number")
			$(".mobile_number").focus()
			validForm = false
		} else if (!numberPattern.test(vital.mobile_number)) {
			$("#mobile_numberErr").text("Mobile Number is not ")
			validForm = false
		} else {
			$("#mobile_numberErr").text("")
		}

		if (vital.visitPurpose ==="") {
			$("#visitErr").text("Please Enter Visit Purpose")
			$(".visitPurpose").focus()
			validForm = false
		}
		//  else if (!numberPattern.test(vital.mobile_number)) {
		// 	$("#mobile_numberErr").text("Mobile Number is not ")
		// 	validForm = false
		// }
		else {
			$("#visitErr").text("")
		}

		// if (userRegister.email !== "") {
		// 	if (!emailPattern.test(userRegister.email)) {
		// 		$("#emailErr").text("Invalid Email")
		// 		validForm = false
		// 	} else {
		// 		$("#emailErr").text("")
		// 	}
		// }

		if (vital.gender ==="") {
			$("#genderErr").text("Please Select Gender")
			$(".gender").focus()
			validForm = false
		} else {
			$("#genderErr").text("")
		}
		// if (userRegister.dob ==="") {
		// 	$("#dobErr").text("Please Select DOB")
		// 	$(".dob").focus()
		// 	validForm = false
		// } else {
		// 	$("#dobErr").text("")
		// }
		// if (userRegister.reg_id ==="" || userRegister.reg_id ===undefined) {
		// 	$("#regIDErr").text("Please Enter Registration ID")
		// 	$(".reg_id").focus()
		// 	validForm = false
		// } else {
		// 	$("#regIDErr").text("")
		// }

		if (!validForm) return false

		return true
	}

	this.validateFinalBill = function (itemsData) {
		var validForm = true
		if (itemsData.service_category === "CONSULTATION" && itemsData.description ==="") {
			$("#consultationErr").text("Please Select Consultation")
			validForm = false
		} else {
			$("#consultationErr").text("")
		}
		if (itemsData.service_category === "SERVICES" && itemsData.description ==="") {
			$("#servicesErr").text("Please Select Services")
			validForm = false
		} else {
			$("#servicesErr").text("")
		}
		if (itemsData.service_category === "WARDS" && itemsData.description ==="") {
			$("#wardsErr").text("Please Select Wards")
			validForm = false
		} else {
			$("#wardsErr").text("")
		}
		
		if (!validForm) return false
		return true
	}	


	this.validateCustomerForm = function (customer) {
		var validForm = true
		if (customer.customer_name ==="") {
			$("#customer_nameErr").text("Please Enter The Customer Name")
			validForm = false
		} else {
			$("#customer_nameErr").text("")
		}
		
		if (!validForm) return false
		return true
	}	

	this.validatePackageForm = function (packages) {
		var validForm = true
		if (packages.package_name ==="") {
			$("#package_nameErr").text("Please Enter The Package Name")
			validForm = false
		} else {
			$("#package_nameErr").text("")
		}
		
		if (!validForm) return false
		return true
	}	

	// vital end  //

	this.validateVendorForm = function (vendor) {
		var validForm = true
		if (vendor.vendorType ==="") {
			$("#vendorTypeErr").text("Please select Vendor")
			validForm = false
		} else {
			$("#vendorTypeErr").text("")
		}

		if (vendor.name ==="") {
			$("#name_Err").text("Please Enter Vendor name")
			validForm = false
		} else if (!onlyname.test(vendor.name)) {
			$("#name_Err").text("Invalid vendor name")
			validForm = false
		} else {
			$("#name_Err").text("")
		}

		if (vendor.phone ==="") {
			$("#vendor_numberErr").text("Please Enter Vendor Mobile Number")
			validForm = false
		} else if (!numberPattern.test(vendor.phone)) {
			$("#vendor_numberErr").text("Mobile Number Should Be of Ten Digits")
			validForm = false
		} else {
			$("#vendor_numberErr").text("")
		}

		if (vendor.contact_name ==="") {
			$("#contact_name_Err").text("Please Enter Contact Name")
			validForm = false
		} else if (!onlyname.test(vendor.contact_name)) {
			$("#contact_name_Err").text("Invalid Contact Name")
			validForm = false
		} else {
			$("#contact_name_Err").text("")
		}

		// if (vendor.address1 ==="") {
		// 	$("#address_Line1Err").text("Please Enter Address")
		// 	validForm = false
		// } else if (!onlyname.test(vendor.address1)) {
		// 	$("#address_Line1Err").text("Invalid Address")
		// 	validForm = false
		// } else {
		// 	$("#address_Line1Err").text("")
		// }

		// if (vendor.address2 ==="") {
		// 	// $("#address_Line2Err").text("Please Enter Address")
		// 	// validForm = false
		// } else if (!onlytext.test(vendor.address2)) {
		// 	$("#address_Line2Err").text("Invalid Address")
		// 	validForm = false
		// } else {
		// 	$("#address_Line2Err").text("")
		// }

		/*if (vendor.contact_phone ==="") {
			$("#contact_numberErr").text("Please Enter Contact Number")
			validForm = false
		} else if (!numberPattern.test(vendor.contact_phone)) {
			$("#contact_numberErr").text("Mobile Number Should Be of Ten Digits")
			validForm = false
		} else {
			$("#contact_numberErr").text("")
		}*/

		/*if (vendor.city ==="") {
			$("#city_Err").text("Please Enter City name")
			validForm = false
		} else if (!onlytext.test(vendor.city)) {
			$("#city_Err").text("Invalid city")
			validForm = false
		} else {
			$("#city_Err").text("")
		}

		if (vendor.state ==="") {
			// 	$("#state_Err").text("Please Enter State name")
			// 	validForm = false
		} else if (!onlytext.test(vendor.state)) {
			$("#state_Err").text("Invalid state")
			validForm = false
		} else {
			$("#state_Err").text("")
		}

		if (vendor.pincode ==="") {
			$("#pincode_Err").text("Please Enter Postal Code")
			validForm = false
		} else if (!postalCode.test(vendor.pincode)) {
			$("#pincode_Err").text("Invalid Postal Code")
			validForm = false
		} else {
			$("#pincode_Err").text("")
		}
		*/
		
		if (!validForm) return false
		return true
	}	

	this.validateServiceInventoryForm = function (serviceinventory) {
		var validForm = true
		// if (serviceinventory.service ==="" || serviceinventory.service ===undefined) {
		// 	$("#serviceErr").text("Please Enter Service Name")
		// 	validForm = false
		// } else if (!summary.test(serviceinventory.service)) {
		// 	$("#serviceErr").text("Invalid Service Name")
		// 	validForm = false
		// } else {
		// 	$("#serviceErr").text("")
		// }

		if (serviceinventory.item_price ==="" ) {
			// $("#item_priceErr").text("Please Enter The Item Price")
			// validForm = false
		} else if (!decimalnumbers.test(serviceinventory.item_price)) {
			$("#item_priceErr").text("Invalid Item Price")
			validForm = false
		} else {
			$("#item_priceErr").text("")
		}

		// if (serviceinventory.service_type.length ===0) {
		// 	$("#service_typeErr").text("Please Select Service Type")
		// 	validForm = false
		// } else {
		// 	$("#service_typeErr").text("")
		// }
		if (!validForm) return false
		return true
	}

	// addvendor form validation ends //

	this.validateInventoryForm = function (inventory) {

		var validForm = true
		if (inventory.manufacturer ==="") {
			$("#manufacturerErr").text("Please Select Manufacturer")
			validForm = false
		} else {
			$("#manufacturerErr").text("")
		}
		if (inventory.distributor ==="") {
			$("#distributorErr").text("Please Select Distributor")
			validForm = false
		} else {
			$("#distributorErr").text("")
		}
		if (inventory.itemType ==="") {
			$("#itemTypeErr").text("Please Select Item Type")
			validForm = false
		} else {
			$("#itemTypeErr").text("")
		}
		if (inventory.itemName ==="") {
			$("#itemNameErr").text("Please Select Item Name")
			validForm = false
		} else {
			$("#itemNameErr").text("")
		}
		if (inventory.genericName ==="") {
			$("#genericNameErr").text("Please Select Generic Name")
			validForm = false
		} else {
			$("#genericNameErr").text("")
		}
		// if (inventory.dosage == "" || parseInt(inventory.dosage) <= 0) {
		// 	$("#dosageErr").text("Please Enter The Dosage(Greater Than Zero)")
		// 	validForm = false
		// } else if (!onlynumbers.test(inventory.dosage)) {
		// 	$("#dosageErr").text("Invalid Dosage")
		// 	validForm = false
		// } else {
		// 	$("#dosageErr").text("")
		// }

	// 	if (inventory.dosage !== "") {
	// 		 if (!onlynumbers.test(inventory.dosage)) {
	// 		$("#dosageErr").text("Invalid Dosage")
	// 		validForm = false
	// 	} else {
	// 		$("#dosageErr").text("")
	// 	}
	// }
		// if (inventory.pack_size === "" || parseInt(inventory.pack_size) <= 0) {
		// 	$("#pack_sizeErr").text("Please Enter The Package Size(Greater Than Zero)")
		// 	validForm = false
		// } else if (!onlynumbers.test(inventory.pack_size)) {
		// 	$("#pack_sizeErr").text("Invalid Pack Size")
		// 	validForm = false
		// } else {
		// 	$("#pack_sizeErr").text("")
		// }

		if (inventory.pack_size === "") {
			$("#pack_sizeErr").text("Please Enter The Package Size")
			validForm = false
		}  else {
			$("#pack_sizeErr").text("")
		}


		if (inventory.qty ==="" || parseInt(inventory.qty) <= 0) {
			$("#qtyErr").text("Please Enter The Quantity")
			validForm = false
		} else if (!onlynumbers.test(inventory.qty)) {
			$("#qtyErr").text("Invalid Quantity")
			validForm = false
		} else {
			$("#qtyErr").text("")
		}
		if (inventory.bin_number ==="") {
			$("#bin_numberErr").text("Please Enter The Batch Number")
			validForm = false
		} else if (parseInt(inventory.bin_number) <= 0) {
			$("#bin_numberErr").text("Please Enter The Batch Number (Greater Than Zero)")
			validForm = false
		} else if (constants.REGEX_ALPH_NUM_HYPHEN.test(inventory.bin_number) ===false) {
			$("#bin_numberErr").text("Invalid Batch-Number")
			validForm = false
		} else {
			$("#bin_numberErr").text("")
		}
		if (inventory.alert_qty ==="" || parseInt(inventory.alert_qty) <= 0) {
			$("#alert_qtyErr").text("Please Enter The Alert Quantity")
			validForm = false
		} else if (!onlynumbers.test(inventory.alert_qty)) {
			$("#alert_qtyErr").text("Invalid Alert Quantity")
			validForm = false
		} else {
			$("#alert_qtyErr").text("")
		}
		 if (inventory.po) {
		 if (parseInt(inventory.po) <= 0) {
			$("#poErr").text("Please Enter The PO (Greater Than Zero)")
			validForm = false
		} else if (constants.REGEX_ALPH_NUM_HYPHEN.test(inventory.po) ===false) {
			$("#poErr").text("Invalid PO")
		} else {
			$("#poErr").text("")
		}
	}
		
		// if (inventory.schedule ==="") {
		// 	$("#scheduleErr").text("Please Enter The Schedule")
		// 	validForm = false
		// } else if (!onlyname.test(inventory.schedule)) {
		// 	$("#scheduleErr").text("Invalid Schedule")
		// 	validForm = false
		// } else {
		// 	$("#scheduleErr").text("")
		// }

		if (inventory.item_price ==="" || parseInt(inventory.item_price) <= 0) {
			$("#item_priceErr").text("Please Enter The MRP Price")
			validForm = false
		} else if (!decimalnumbers.test(inventory.item_price)) {
			$("#item_priceErr").text("Invalid MRP Price")
			validForm = false
		} else {
			$("#item_priceErr").text("")
		}
		if (inventory.purchase_price ==="" || parseInt(inventory.purchase_price) <= 0) {
			$("#purchase_priceErr").text("Please Enter The Purchase Price")
			validForm = false
		} else if (!decimalnumbers.test(inventory.purchase_price)) {
			$("#purchase_priceErr").text("Invalid Purchase Price")
			validForm = false
		} else {
			$("#purchase_priceErr").text("")
		}
		if (!validForm) return false
		return true
	}

	this.validateManageLookup = function (state) {
		var validForm = true

		if (state.lookup_name ==="" || state.lookup_name ===undefined) {
			$("#lookupTypeErr").text("Please Select Type")
			validForm = false
		} else {
			$("#lookupTypeErr").text("")
		}
		if (state.lookup_value ==="" || state.lookup_value ===undefined) {
			$("#lookupValueErr").text("Please Enter The Item Name")
			validForm = false
		} 
		// else if (!medicine_name.test(state.lookup_value)) {
		// 	$("#lookupValueErr").text("Invalid Name")
		// 	validForm = false
		// } 
		else {
			$("#lookupValueErr").text("")
		}

		if (state.lookup_value_code ==="" || state.lookup_value_code ===undefined) {
			$("#lookupValueCodeErr").text("Please Enter The Item Code")
			validForm = false
		}
		//commented by anil
		// else if (!onlynumbers.test(state.lookup_value_code)) {
		// 	$("#lookupValueCodeErr").text("Invalid Code")
		else if (constants.REGEX_ALPH_NUM_HYPHEN.test(state.lookup_value_code) ===false) {
			$("#lookupValueCodeErr").text("Invalid Code")
			validForm = false
		} else {
			$("#lookupValueCodeErr").text("")
		}

		if (!validForm) return false
		return true
	}

	this.validatePurchaseForm = function (purchase) {
		var validForm = true
		//console.log(purchase)
		if (purchase.manufacturer ==="") {
			$("#manufacturerErr").text("Please Select Manufacturer")
			validForm = false
		} else {
			$("#manufacturerErr").text("")
		}
		if (purchase.distributor ==="") {
			$("#distributorErr").text("Please Select Distributor")
			validForm = false
		} else {
			$("#distributorErr").text("")
		}

		// if (purchase.po ==="") {
		// 	$("#poErr").text("Please Enter The PO")
		// 	validForm = false
		// } else if (parseInt(purchase.po) <= 0) {
		// 	$("#poErr").text("Please Enter The PO (Greater Than Zero)")
		// 	validForm = false
		// } else if (constants.REGEX_ALPH_NUM_HYPHEN.test(purchase.po) ===false) {
		// 	$("#poErr").text("Invalid PO")
		// } else {
		// 	$("#poErr").text("")
		// }

		if (purchase.po) {
			if (parseInt(purchase.po) <= 0) {
			   $("#poErr").text("Please Enter The PO (Greater Than Zero)")
			   validForm = false
		   } else if (constants.REGEX_ALPH_NUM_HYPHEN.test(purchase.po) ===false) {
			   $("#poErr").text("Invalid PO")
		   } else {
			   $("#poErr").text("")
		   }
	   }


		// if (purchase.hsn_code ==="") {
		// 	$("#hsn_code_Err").text("Please Enter The HSN Code")
		// 	validForm = false
		// } else if (!num_with_space.test(purchase.hsn_code)) {
		// 	$("#hsn_code_Err").text("Invalid HSN Code")
		// } else {
		// 	$("#hsn_code_Err").text("")
		// }

		if (!validForm) return false
		return true
	}

	this.validateSaleForm = function (state) {
		var validForm = true
		if (state.doctorName ==="") {
			$("#NameErr").text("Please Enter The Doctor Name")
			validForm = false
		} else if (!onlyname.test(state.doctorName)) {
			$("#NameErr").text("Invalid Doctor Name")
			validForm = false
		} else {
			$("#NameErr").text("")
		}
		if (state.patientName ==="") {
			$("#patientNameErr").text("Please Enter The Patient Name")
			validForm = false
		} else if (!onlyname.test(state.patientName)) {
			$("#patientNameErr").text("Invalid Patient Name")
			validForm = false
		} else {
			$("#patientNameErr").text("")
		}
		// if (state.department ==='') {
		// 	$('#deptErr').text('Please Select Department')
		// 	validForm = false
		// } else {
		// 	$('#deptErr').text('')
		// }

		// if (state.patientRecNo ==="") {
		// 	// $("#patientRecNOErr").text("Please Enter The Patient RecNO")
		// 	// validForm = false
		// }  else if(!onlytext.test(state.patientRecNo)) {
		// 	$('#patientRecNOErr').text("Invalid Patient RecNO")
		// 		validForm = false
		// 	$("#patientRecNOErr").text("")
		// }

		if (state.patientAge ==="") {
			// $("#patientAgeErr").text("Please Enter The Patient Age")
			// validForm = false
		} else if (!onlynumbers.test(state.patientAge)) {
			$("#patientAgeErr").text("Invalid Patient Age")
			validForm = false
		} else {
			$("#patientAgeErr").text("")
		}

		if (state.patientDOB ==="") {
			// $("#patientDOBErr").text("Please Select DOB")
			// validForm = false
		} else {
			$("#patientDOBErr").text("")
		}
		if (state.patientGender ==="") {
			// $("#genderErr").text("Please Select Gender")
			// validForm = false
		} else {
			$("#genderErr").text("")
		}
		
		if (state.patientSource ==="") {
			$("#sourceErr").text("Please Select Source")
			validForm = false
		} else {
			$("#sourceErr").text("")
		}
	
		if (state.patientMobile ==="") {
			// $("#patientMobile").text("Please Enter Mobile Number")
			// validForm = false
		} else if (!numberPattern.test(state.patientMobile)) {
			$("#patientMobile").text("Mobile Number Should Be of Ten Digits")
			validForm = false
		} else {
			$("#patientMobile").text("")
		}

		if (state.patientAddress ==="") {
			// $("#patientAddress").text("Please Enter The Patient Address")
			// validForm = false
		} else if (!address.test(state.patientAddress)) {
			$("#patientAddress").text("Invalid Patient Address")
			validForm = false
		} else {
			$("#patientAddress").text("")
		}

		if (!validForm) return false
		return true
	}

	this.validateTransferForm = function (state) {
		var validForm = true

		if (state.to_departmentName ==="" || state.to_departmentName ==="To Department Name") {
			$("#sourceErr").text("Please Select Department")
			validForm = false
		} else {
			$("#sourceErr").text("")
		}
		if (!validForm) return false
		return true
	}

	this.checkinForm = function (checkinForm) {   
		var validForm = true
		if (checkinForm.category_type ==="" || checkinForm.category_type === "select" ) {
			$("#categorytypeErr").text("Please Select Category Type")
			validForm = false
		} else {
			$("#categorytypeErr").text("")
		}
		if (checkinForm.visit_type ==="") {
			$("#visitTypeErr").text("Please Select Visit Type")
			validForm = false
		} else {
			$("#visitTypeErr").text("")
		}
		if (checkinForm.check_up ==="") {
			$("#checkUpErr").text("Please Select Visit Reason")
			validForm = false
		} else {
			$("#checkUpErr").text("")
		}
		if (checkinForm.doctor_name ==="" || checkinForm.doctor_name ===undefined) {
			$("#doctorErr").text("Please Select a Doctor")
			validForm = false
		} else {
			$("#doctorErr").text("")
		}

		// let form_time = checkinForm.in_time.split(":")
		// 	let original_time = moment().format("HH:mm").split(":")
		// 	if (form_time[0] < original_time[0]) {
		// 		$("#timeErr").text("Check Hours")
		// 		validForm = false
		// 	} else {
		// 		if (form_time[0] ===original_time[0]) {
		// 			if (form_time[1] < original_time[1]) {
		// 				$("#timeErr").text("Check Minutes")
		// 				validForm = false
		// 			} else {
		// 				$("#timeErr").text("")
		// 			}
		// 		} else {
		// 			$("#timeErr").text("")
		// 		}
		// 	}

		if (!validForm) return false
		return true
	}

	this.checkinFormwaiting = function (checkinFormwaiting) {   
		var validForm = true
		if (checkinFormwaiting.category_type ==="" || checkinFormwaiting.category_type === "select" ) {
			$("#categorytypeErr").text("Please Select Category Type")
			validForm = false
		} else {
			$("#categorytypeErr").text("")
		}
		if (checkinFormwaiting.category_type === "OP" ||checkinFormwaiting.category_type === "Discharge" ) {
		
		if (checkinFormwaiting.visit_type ==="") {
			$("#visitTypeErr").text("Please Select Visit Type")
			validForm = false
		} else {
			$("#visitTypeErr").text("")
		}
		if (checkinFormwaiting.check_up ==="") {
			$("#checkUpErr").text("Please Select Visit Reason")
			validForm = false
		} else {
			$("#checkUpErr").text("")
		}
	}
		if (checkinFormwaiting.doctor_name ==="" || checkinFormwaiting.doctor_name ===undefined) {
			$("#doctorErr").text("Please Select a Doctor")
			validForm = false
		} else {
			$("#doctorErr").text("")
		}
		if (!validForm) return false
		return true
	}
	this.doctorAssignForm = function (checkinForm) {
		var validForm = true

		if (checkinForm.doctor ==="") {
			$("#doctorErr").text("Please Select a Doctor")
			validForm = false
		} else {
			$("#checkUpErr").text("")
		}

		if (!validForm) return false

		return true
	}

	this.appointmentForm = function (appointmentForm) {
		var validForm = true

		if (appointmentForm.in_date ===moment().format("YYYY-MM-DD")) {
			let form_time = appointmentForm.in_time.split(":")
			let original_time = moment().format("HH:mm").split(":")
			if (form_time[0] < original_time[0]) {
				$("#timeErr").text("Check Hours")
				validForm = false
			} else {
				if (form_time[0] ===original_time[0]) {
					if (form_time[1] < original_time[1]) {
						$("#timeErr").text("Check Minutes")
						validForm = false
					} else {
						$("#timeErr").text("")
					}
				} else {
					$("#timeErr").text("")
				}
			}
		}
		if (appointmentForm.category_type ==="") {
			$("#categorytypeErr").text("Please Select Category Type")
			validForm = false
		} else {
			$("#categorytypeErr").text("")
		}
		if (appointmentForm.visit_type ==="") {
			$("#visitTypeErr").text("Please Enter Visit Type")
			validForm = false
		} else {
			$("#visitTypeErr").text("")
		}
		if (appointmentForm.check_up ==="") {
			$("#checkUpErr").text("Please Enter Visit Reason")
			validForm = false
		} else {
			$("#checkUpErr").text("")
		}
		if (appointmentForm.doctor ==="") {
			$("#doctorErr").text("Please Select a Doctor")
			validForm = false
		} else {
			$("#doctorErr").text("")
		}

		if (!validForm) return false

		return true
	}

	this.validateLabManageForm = function (state) {
		var validForm = true

		if (state.test_name ==="") {
			$("#testnameErr").text("Please Enter The Test Name")
			validForm = false
		}
		// else if (constants.REGEX_ALPH_NUM_HYPHEN.test(state.test_name) ===false) {
		// 	$("#testnameErr").text("Invalid Name")
		// 	validForm = false
		// }
		else {
			$("#testnameErr").text("")
		}
		// if (state.standard_range ==="" ) {
		// 	// $("#categoryErr").text("Please Enter Standard Range")
		// 	// validForm = false
		// }	else if (constants.REGEX_ALPH_NUM_HYPHEN.test(state.standard_range) ===false) {
		// 	$("#standardrangeErr").text("Allows only alphanumeric(a-zA-Z0-9) and Hyphen(-)")
		// 	validForm = false
		// } else {
		// 	$("#standardrangeErr").text("")
		// }
		if (state.category ==="") {
			$("#categoryErr").text("Please Enter The Category")
			validForm = false
		} else if (!onlyname.test(state.notes)) {
			$("#categoryErr").text("Invalid Category")
			validForm = false
		} else {
			$("#categoryErr").text("")
		}
		if (state.code ==="") {
			$("#codeErr").text("Please Enter The Code")
			validForm = false
		}
		// else if (constants.REGEX_ALPH_NUM_HYPHEN.test(state.code) ===false) {
		// 	$("#codeErr").text("Invalid Code")
		// 	validForm = false
		// }
		else {
			$("#codeErr").text("")
		}
		if (state.price ==="" || parseInt(state.price) < 0) {
			$("#priceErr").text("Please Enter The Price")
			validForm = false
		} else if (!onlynumbers.test(state.price)) {
			$("#priceErr").text("Invalid Price")
			validForm = false
		} else {
			$("#priceErr").text("")
		}

		if (state.discount ==="") {
			// $("#discountErr").text("Please Enter The Discount")
			// validForm = false
		} else if (!onlynumbers.test(state.discount) || parseInt(state.discount) > 100) {
			$("#discountErr").text("Invalid Discount")
			validForm = false
		} else {
			$("#discountErr").text("")
		}

		if (!validForm) return false
		return true
	}

	this.validateLabManageTmpForm = function (state) {
		var validForm = true

		if (state.test_package_name ==="") {
			$("#test_package_nameErr").text("Please Enter The Package Name")
			validForm = false
		}
		// else if (constants.REGEX_ALPH_NUM_HYPHEN.test(state.test_package_name) ===false) {
		// 	$("#test_package_nameErr").text("Invalid Package Name")
		// 	validForm = false
		// }
		else {
			$("#test_package_nameErr").text("")
		}
		if (state.selectedTests ==="") {
			$("#selectedTestsErr").text("Please Select Tests")
			validForm = false
		}
		// else if (!onlyname.test(state.selectedTests)) {
		// 	$("#selectedTestsErr").text("Invalid Tests")
		// 	validForm = false
		// }
		else {
			$("#selectedTestsErr").text("")
		}

		if (state.price ==="") {
			$("#price_Err").text("Please Enter The Price")
			validForm = false
		} else if (!onlynumbers.test(state.price) || parseInt(state.price) < 0) {
			$("#price_Err").text("Invalid Price")
			validForm = false
		} else {
			$("#price_Err").text("")
		}
		if (state.discount ==="") {
			// $("#discountErr").text("Please Enter The Discount")
			// validForm = false
		} else if (!onlynumbers.test(state.discount) || parseInt(state.discount) > 100) {
			$("#discount_Err").text("Invalid Discount")
			validForm = false
		} else {
			$("#discount_Err").text("")
		}

		if (!validForm) return false
		return true
	}

	this.validateLabOrderDetailsExternalForm = function (state) {
		var validForm = true
		if (state.external_lab ==="") {
			$("#external_labErr").text("Please Select The External Lab")
			validForm = false
		}
		if (!validForm)
			// } else if (onlyname.test(state.patient_value)) {
			// 	$("#patient_valueErr").text("Invalid Patient Name")
			// 	validForm = false

			return false
		return true
	}
	this.validateLabOrderDetailsForm = function (tests, report) {
		var validForm = true
		if (tests) {
			tests.map((item) => {
				if (_.find(report.tests, { test_id: item.test_id })) {
					let val = _.find(report.tests, { test_id: item.test_id }).patient_value
					let val2 = _.find(report.tests, { test_id: item.test_id }).testStatus
					if (val ===undefined || val ==="") {
						if (val2 ===undefined || val2 ==="" || val2.testStatus ==="Done") {
							$(`#patient_valueErr_${item.test_id}`).text("Please Enter Patient Value")
							validForm = false
						} else {
							$(`#patient_valueErr_${item.test_id}`).text("")
						}
					} else {
						$(`#patient_valueErr_${item.test_id}`).text("")
					}
				} else {
					$(`#patient_valueErr_${item.test_id}`).text("Please Enter Patient Value")
					validForm = false
				}
			})

			// tests.map((item) => {
			// 	if(!item.hasOwnProperty("patient_value")){
			// 		validForm = false
			// 	}
			// })
			// tests.map((item) => {
			// 	if(item.patient_value ===""){
			// 		validForm = false
			// 	}
			// })
		} else {
			validForm = false
		}
		if (!validForm) {
			return false
		} else {
			return true
		}
	}

	this.extAppointment = function (extAppointment) {
		var validForm = true

		if (extAppointment.visit_type ==="") {
			$("#visitTypeErr").text("Please Enter Visit Type")
			validForm = false
		} else {
			$("#visitTypeErr").text("")
		}
		if (extAppointment.check_up ==="") {
			$("#checkUpErr").text("Please Enter Visit Reason")
			validForm = false
		} else {
			$("#checkUpErr").text("")
		}
		if (extAppointment.doctor ==="") {
			$("#doctorErr").text("Please Select a Doctor")
			validForm = false
		} else {
			$("#doctorErr").text("")
		}

		if (!validForm) return false

		return true
	}

	this.appointmentinForm = function (appointmentinForm) {
		var validForm = true
		if (appointmentinForm.dates.includes(appointmentinForm.in_date)) {
			$("#dateErr").text("This date was already in scheduled")
			validForm = false
		} else {
			$("#dateErr").text("")
		}
		if (appointmentinForm.in_date ===moment().format("YYYY-MM-DD")) {
			let form_time = appointmentinForm.in_time.split(":")
			let original_time = moment().format("HH:mm").split(":")
			if (form_time[0] < original_time[0]) {
				$("#timeErr").text("Check Hours")
				validForm = false
			} else {
				if (form_time[0] ===original_time[0]) {
					if (form_time[1] < original_time[1]) {
						$("#timeErr").text("Check Minutes")
						validForm = false
					} else {
						$("#timeErr").text("")
					}
				} else {
					$("#timeErr").text("")
				}
			}
		}
		if (appointmentinForm.category_type ==="" || appointmentinForm.category_type === "select") {
			$("#categorytypeErr").text("Please Select Category Type")
			validForm = false
		} else {
			$("#categorytypeErr").text("")
		}
		if (appointmentinForm.visit_type ==="") {
			$("#visitTypeErr").text("Please Enter Visit Type")
			validForm = false
		} else {
			$("#visitTypeErr").text("")
		}
		if (appointmentinForm.check_up ==="") {
			$("#checkUpErr").text("Please Enter Visit Reason")
			validForm = false
		} else {
			$("#checkUpErr").text("")
		}
		if (appointmentinForm.doctor ==="") {
			$("#doctorErr").text("Please Select a Doctor")
			validForm = false
		} else {
			$("#doctorErr").text("")
		}

		if (!validForm) return false
		console.log(!validForm)
		return true
	}
	this.vaccinationForm = function (vaccinationForm) {
		var validForm = true

		if (vaccinationForm.vaccination_type ==="") {
			$("#vaccination_typeErr").text("Please Enter Vaccination Type")
			validForm = false
		} else {
			$("#vaccination_typeErr").text("")
		}
		if (vaccinationForm.vaccination_name ==="") {
			$("#vaccination_nameErr").text("Please Enter Vaccination Name")
			validForm = false
		} else {
			$("#vaccination_nameErr").text("")
		}
		if (vaccinationForm.due_date ==="") {
			$("#due_dateErr").text("Please Enter Vaccination Due Date")
			validForm = false
		} else {
			$("#due_dateErr").text("")
		}

		if (!validForm) return false

		return true
	}
	this.validateConnectionForm = function (createConnection) {
		var validForm = true
		if (createConnection.connection_name ==="") {
			$("#connection_nameErr").text("Field is Required")
			validForm = false
		} else {
			$("#connection_nameErr").text("")
		}
		/*        if(createConnection.url_actions.name===''){
            $('#nameErr').text('Field is Required');
             validForm = false; 
       } else {
             $('#nameErr').text('');
       }*/
		if (createConnection.end_url ==="") {
			$("#end_urlErr").text("Field is Required")
			validForm = false
		} else {
			$("#end_urlErr").text("")
		}
		if (createConnection.service_type ==="") {
			$("#service_typeErr").text("Field is Required")
			validForm = false
		} else {
			$("#service_typeErr").text("")
		}
		if (!validForm) return false

		return true
	}
	this.validateActionForm = function (action) {
		var validForm = true
		if (action.name ==="") {
			$("#nameErr").text("Field is Required")
			validForm = false
		} else {
			$("#nameErr").text("")
		}
		if (action.ext_url ==="") {
			$("#ext_urlErr").text("Field is Required")
			validForm = false
		} else {
			$("#ext_urlErr").text("")
		}
		if (!validForm) return false

		return true
	}
	this.AdminUserForm = function (adminUserRegister) {
		var validForm = true
		if (adminUserRegister.firstName ==="") {
			$("#firstNameErr").text("Please Enter Given Name")
			validForm = false
		} else {
			$("#firstNameErr").text("")
		}
		if (adminUserRegister.lastName ==="") {
			$("#lastNameErr").text("Please Enter Surname")
			validForm = false
		} else {
			$("#lastNameErr").text("")
		}
		if (adminUserRegister.email ==="") {
			$("#emailErr").text("Please Enter Valid Email")
			validForm = false
		} else if (!emailPattern.test(adminUserRegister.email)) {
			$("#emailErr").text("Invalid Email")
			validForm = false
		} else {
			$("#emailErr").text("")
		}
		if (adminUserRegister.user_role ==="") {
			$("#user_roleErr").text("Please select Type")
			validForm = false
		} else {
			$("#user_roleErr").text("")
		}
		if (adminUserRegister.client_id ==="") {
			$("#client_idErr").text("Please select Type")
		} else {
			$("#client_idErr").text("")
		}
		if (adminUserRegister.mobile_number ==="") {
		} else if (!numberPattern.test(adminUserRegister.mobile_number)) {
			$("#mobile_numberErr").text("Mobile Number Should Be of Ten Digits")
			validForm = false
		} else {
			$("#mobile_numberErr").text("")
		}
		if (adminUserRegister.status ==="") {
			$("#statusErr").text("Please select status")
			validForm = false
		} else {
			$("#statusErr").text("")
		}
		if (!validForm) return false

		return true
	}
	this.changePasswordForm = function (changePassword) {
		var validForm = true
		if (changePassword.oldPassword ==="") {
			$("#oldPasswordErr").text("Please enter old password")
			validForm = false
		} else {
			$("#oldPasswordErr").text("")
		}
		if (changePassword.password ==="") {
			$("#passwordErr").text("Please enter new password")
			validForm = false
		} else if (changePassword.password ===changePassword.oldPassword) {
			$("#passwordErr").text("New password should not be same as old password")
			validForm = false
		} else if (changePassword.password.length < 8) {
			$("#passwordErr").text("Password must have minimum 8 characters")
			validForm = false
		} else {
			$("#passwordErr").text("")
		}
		if (changePassword.confirmPassword ==="") {
			$("#confirmPasswordErr").text("Please enter confirm password")
			validForm = false
		} else if (changePassword.confirmPassword !== changePassword.password) {
			$("#confirmPasswordErr").text("New password and confirm password should be same")
			validForm = false
		} else {
			$("#confirmPasswordErr").text("")
		}

		if (!validForm) return false
		return true
	}

	this.setPasswordForm = function (setPassword) {
		var regex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*])(?=.{8,})")
		var validForm = true
		if (setPassword.password ==="") {
			$("#passwordErr").text("Please enter password")
			validForm = false
		} else if (setPassword.password.length < 8) {
			$("#passwordErr").text("Password must have minimum 8 characters")
			validForm = false
		} else {
			$("#PasswordErr").text("")
		}
		if (setPassword.confirmpassword ==="") {
			$("#confirmpasswordErr").text("Please enter confirm password")
			validForm = false
		} else if (setPassword.confirmpassword !== setPassword.password) {
			$("#confirmpasswordErr").text("password and confirm password should be same! !!!")
			validForm = false
		} else {
			$("#confirmPasswordErr").text("")
		}

		if (!validForm) return false
		return true
	}

	this.userInfoForm = function (userInfo) {
		var regex = "/^d{10}$/"
		var validForm = true

		if (userInfo.firstName ==="") {
			$("#firstNameErr").text("Please Enter Given Name")
			validForm = false
		} else {
			$("#firstNameErr").text("")
		}
		if (userInfo.lastName ==="") {
			$("#lastNameErr").text("Please Enter Surname")
			validForm = false
		} else {
			$("#lastNameErr").text("")
		}

		if (userInfo.mobile_number ==="") {
		} else if (!numberPattern.test(userInfo.mobile_number)) {
			$("#mobile_numberErr").text("please Enter Valid number")
			validForm = false
		} else {
			$("#mobile_numberErr").text("")
		}
		if (userInfo.email ==="") {
			$("#emailErr").text("Please Enter Email")
			validForm = false
		} else if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(userInfo.email)) {
			$("#emailErr").text("please Enter Valid Email")
			validForm = false
		} else {
			$("#emailErr").text("")
		}

		if (!validForm) return false
		return true
	}

	this.validateEntityForm = function (entityInfo) {
		var validForm = true
		var spaceRegex = "/s/g"
		if (entityInfo.entity.entityName ==="") {
			$("#entityNameErr").text("Please Enter EntityName")
			validForm = false
		} else if (/\s/g.test(entityInfo.entity.entityName)) {
			$("#entityNameErr").text("Name should not contain spaces")
			validForm = false
		} else {
			$("#entityNameErr").text("")
		}
		if (entityInfo.entity.values ===0 && entityInfo.entityValue.value ==="") {
			// $('#valuesErr').text('Please Enter Value');
			$("#valuesErr").text("")
			validForm = false
		} else {
			$("#valuesErr").text("")
		}
		if (entityInfo.entityValue.synonyms !== 0 && entityInfo.entityValue.value ==="") {
			$("#valueSynErr").text("Please Enter Value")
			validForm = false
		} else {
			$("#valueErr").text("")
		}
		if (!validForm) return false
		return true
	}
	this.validateDialogueNodeUrl = function (editNodeInfo) {
		var validForm = true

		if (editNodeInfo.service_config.service_id ==="" || editNodeInfo.service_config.service_id ===undefined) {
			$("#connection_nameErr").text("Field is Required")
			validForm = false
		} else {
			$("#connection_nameErr").text("")
		}
		if (editNodeInfo.service_config.url_action.name ==="") {
			$("#nameErr").text("Field is Required")
			validForm = false
		} else {
			$("#nameErr").text("")
		}
		if (!validForm) return false
		return true
	}
	this.validateDiaglodeConditons = function (botValues) {
		var validForm = true

		if (botValues.value ==="" || botValues.type ==="" || botValues.type !== botValues.value[0]) {
			$("#conditionNameErr").text("Condition is Required")
			validForm = false
		} else {
			$("#conditionNameErr").text("")
		}
		if (!validForm) return false
		return true
	}

	this.validateclientConfigfb = function (fb) {
		var validForm = true

		if (fb.verifyToken ==="" || fb.verifyToken ===undefined) {
			$("#verifyTokenErr").text("Enter verify token.")
			validForm = false
		} else {
			$("#verifyTokenErr").text("")
		}

		if (fb.accessToken ==="" || fb.accessToken ===undefined) {
			$("#accessTokenErr").text("Enter access token.")
			validForm = false
		} else {
			$("#accessTokenErr").text("")
		}

		if (!validForm) return false
		return true
	}
	this.validateclientConfigEmail = function (configEmail) {
		var validForm = true
		if (configEmail.authName !== "" && !/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(configEmail.authName)) {
			$("#authNameErr").text("Invalid gmail.")
			validForm = false
		} else {
			$("#authNameErr").text("")
		}

		if (!validForm) return false
		return true
	}

	this.validateWsdl = function (wsdlFields) {
		var validForm = true

		if (wsdlFields.end_url ==="") {
			$("#errMsgs").text("Wsdl_url is Required")
			validForm = false
		} else {
			$("#errMsgs").text("")
		}

		if (!validForm) return false
		return true
	}

	this.validateChatLog = function (chatLogs, count) {
		var validForm = true
		var from_date = new Date(chatLogs.from_date)
		var to_date = new Date(chatLogs.to_date)
		if (chatLogs.to_date ==="" || chatLogs.to_date ===null) {
			$("#to_dateErr").text("Field is Required")
			validForm = false
		} else {
			$("#to_dateErr").text("")
		}
		if (chatLogs.from_date ==="" || chatLogs.to_date ===null) {
			$("#from_dateErr").text("Field is Required")
			validForm = false
		} else {
			$("#from_dateErr").text("")
		}
		if (chatLogs.status ==="") {
			$("#statusErr").text("Field is Required")
			validForm = false
		} else {
			$("#statusErr").text("")
		}
		if (from_date > to_date) {
			$("#wrongdateErr").text("Dates are not given correctly")
			validForm = false
		} else if (count >= 31) {
			$("#diffdateErr").text("Dates should be with in month")
			validForm = false
		} else {
			$("#wrongdateErr").text("")
			$("#diffdateErr").text("")
		}
		if (!validForm) return false
		return true
	}

	this.validatelineChart = function (lineChart) {
		var validForm = true
		var from = moment(lineChart.fromDate)
		var to = moment(lineChart.toDate)
		var days = to.diff(from, "days")
		if (days >= 31) {
			$("#monthErr").text("Dates should be with in month")
			validForm = false
		} else {
			$("#monthErr").text("")
		}
		if (lineChart.toDate ==="" || lineChart.toDate ===null) {
			$("#toDateErr").text("Field is Required")
			validForm = false
		} else {
			$("#toDateErr").text("")
		}
		if (lineChart.fromDate ==="" || lineChart.fromDate ===null) {
			$("#fromDateErr").text("Field is Required")
			validForm = false
		} else {
			$("#fromDateErr").text("")
		}
		if (lineChart.fromDate ==="" || lineChart.fromDate ===null || lineChart.toDate ==="" || lineChart.toDate ===null) {
		} else {
			if (lineChart.fromDate > lineChart.toDate) {
				$("#dateErr").text("Dates are not given correctly")
				validForm = false
			} else {
				$("#dateErr").text("")
			}
		}

		if (!validForm) return false
		return true
	}

	this.validateInitActionForm = function (initAction) {
		var validForm = true

		if (initAction.name ==="") {
			$("#nameIErr").text("name is Required")
			validForm = false
		} else {
			$("#nameIErr").text("")
		}
		if (initAction.ext_url ==="") {
			$("#ext_urlIErr").text("ext_url is Required")
			validForm = false
		} else {
			$("#ext_urlIErr").text("")
		}

		if (!validForm) return false
		return true
	}
	this.validatePasswordPolicyForm = function (passwordPolicy) {
		var numericPattern = /^(0|[1-9][0-9]*)$/
		var validForm = true
		if (passwordPolicy.passwordLength ==="" || passwordPolicy.passwordLength ===undefined) {
			$("#passwordLengthErr").text("Field is  Required")
			validForm = false
		} else {
			$("#passwordLengthErr").text("")
		}
		if (passwordPolicy.enablePassword ===true && (passwordPolicy.expiresIn ==="" || passwordPolicy.expiresIn ===undefined)) {
			$("#expiresInErr").text("Field is  Required")
			validForm = false
		} else {
			$("#expiresInErr").text("")
		}
		if (!validForm) return false
		return true
	}

	this.validateSingleSignForm = function (ssoInfo) {
		var validForm = true
		if (ssoInfo.identity_provider.sso_login_url ==="" || ssoInfo.identity_provider.sso_login_url ===undefined) {
			$("#sso_login_urlErr").text("Field is  Required")
			validForm = false
		} else {
			$("#sso_login_urlErr").text("")
		}
		if (ssoInfo.identity_provider.sso_logout_url ==="" || ssoInfo.identity_provider.sso_logout_url ===undefined) {
			$("#sso_logout_urlErr").text("Field is  Required")
			validForm = false
		} else {
			$("#sso_logout_urlErr").text("")
		}
		if (ssoInfo.identity_provider.certificates ==="" || ssoInfo.identity_provider.certificates ===undefined) {
			$("#certificatesErr").text("Field is  Required")
			validForm = false
		} else {
			$("#certificatesErr").text("")
		}

		if (!validForm) return false
		return true
	}

	this.validateEmailConfiguration = function (data) {
		var stmpport = parseInt(data.smtpCredentials.port)
		var imapport = parseInt(data.imapCredentials.port)
		var validForm = true
		if (data.password ==="" || data.password ===undefined) {
			$("#passwordErr").text("Field is  Required")
			validForm = false
		} else {
			$("#passwordErr").text("")
		}
		if (data.smtpCredentials.host ==="" || data.smtpCredentials.host ===undefined) {
			$("#smtphostErrMsg").text("Field is  Required")
			validForm = false
		} else {
			$("#smtphostErrMsg").text("")
		}
		/*if(data.smtpCredentials.service ==='' || data.smtpCredentials.service ===undefined){
          $('#smtpserviceErrMsg').text('Field is  Required');
                validForm = false;
        } else {
            $('#smtpserviceErrMsg').text('');
        }*/
		if (data.smtpCredentials.port ==="" || data.smtpCredentials.port ===undefined) {
			$("#smtpportErrMsg").text("Field is  Required")
			validForm = false
		} else {
			if (/^\d+$/.test(data.smtpCredentials.port)) {
				$("#smtpportErrMsg").text("")
			} else {
				$("#smtpportErrMsg").text("Allows Numbers Only ")
				validForm = false
			}
		}
		if (stmpport >= 65536) {
			$("#portErrMsg").text("Max port number exceeded")
			validForm = false
		} else {
			$("#portErrMsg").text("")
		}
		if (data.imapCredentials.host ==="" || data.imapCredentials.host ===undefined) {
			$("#imaphostErrMsg").text("Field is  Required")
			validForm = false
		} else {
			$("#imaphostErrMsg").text("")
		}
		if (data.imapCredentials.port ==="" || data.imapCredentials.port ===undefined) {
			$("#imapportErrMsg").text("Field is  Required")
			validForm = false
		} else {
			if (/^\d+$/.test(data.imapCredentials.port)) {
				$("#imapportErrMsg").text("")
			} else {
				$("#imapportErrMsg").text("Allows Numbers Only ")
				validForm = false
			}
		}
		if (imapport >= 65536) {
			$("#imapportExceedErrMsg").text("Max port number exceeded")
			validForm = false
		} else {
			$("#imapportExceedErrMsg").text("")
		}
		if (!validForm) return false
		return true
	}

	this.validateSmSConfiguration = function (data) {
		var validForm = true
		if (data.accountSID ==="" && data.authToken !== "") {
			$("#accountSIDErr").text("Field is  Required")
			validForm = false
		} else if (data.accountSID !== "" && data.authToken ==="") {
			validForm = false
			$("#authTokenErr").text("Field is  Required")
		} else {
			$("#authTokenErr").text("")
			$("#accountSIDErr").text("")
		}
		if (!validForm) return false
		return true
	}

	this.validateSlackConfiguration = function (data) {
		var validForm = true
		if (data.slack_client_id ==="" && data.client_secret ==="" && data.code ==="") {
			$("#slack_client_idErr").text("Field is  Required")
			$("#client_secretErr").text("Field is  Required")
			$("#codeErr").text("Field is  Required")
			validForm = false
		} else if (data.slack_client_id ==="" && data.client_secret !== "" && data.code !== "") {
			$("#slack_client_idErr").text("Field is  Required")
			validForm = false
		} else if (data.slack_client_id !== "" && data.client_secret ==="" && data.code !== "") {
			$("#client_secretErr").text("Field is  Required")
			validForm = false
		} else if (data.slack_client_id !== "" && data.client_secret !== "" && data.code ==="") {
			$("#codeErr").text("Field is  Required")
			validForm = false
		} else {
			$("#slack_client_idErr").text("")
			$("#client_secretErr").text("")
			$("#codeErr").text("")
			validForm = true
		}

		if (!validForm) {
			return false
		} else {
			return true
		}
	}
}

formValidations = new formValidations()

export default formValidations
